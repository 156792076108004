<template>
  <div class="h-full w-full flex overflow-hidden">
    <Scrollbar class="w-full px-8 py-5">
      <div class="space-y-6 w-[880px]">
        <div class="p-6 space-y-4 bg-black/5 rounded-2xl">
          <p class="text-primary font-semibold text-lg leading-7">
            Project details
          </p>
          <NewAsyncTextInput
            ref="projectNameInput"
            label="Project name"
            :value="internalProjectName"
            type="text"
            @update="updateProjectName(project.id!, $event)"
          />

          <NewAsyncTextInput
            ref="contactForIncidentReportingInput"
            label="Contact for incident reporting"
            placeholder="Insert email address ( contact@company.com )"
            :value="internalContactForComplaint"
            type="text"
            @update="updateSupevisorEmail(project.id!, $event)"
          />

          <GenericAutoComplete
            v-model="selectedCslStyle"
            :items="cslStyleListItems"
            :item-options="{
              displayProperty: 'text',
              filterProperties: ['text'],
              valueProperty: 'value',
            }"
            placeholder="Select Citation style"
            @update:model-value="updateCslStyle"
          >
            <template #label>
              <label
                class="text-sm text-primary font-medium flex gap-2 items-center"
              >
                Citation style
                <FloatingMenu>
                  <template #activator="{ show }">
                    <button class="block" @click.stop="show">
                      <InformationCircleIcon class="w-5 h-5 text-black/80" />
                    </button>
                  </template>
                  <template #content>
                    <div class="w-64 p-2">
                      <p>
                        Select your preferred citation style from the list,
                        which will apply to in-text citations and the
                        bibliography in project documents.
                      </p>
                      <div>
                        <a
                          class="text-primary underline"
                          target="_blank"
                          href="/docs/projects.html#citation-style"
                          @click.stop=""
                        >
                          Learn more
                        </a>
                      </div>
                    </div>
                  </template>
                </FloatingMenu>
              </label>
            </template>
            <template #item="{ text }">
              {{ text }}
            </template>
          </GenericAutoComplete>
        </div>
        <div class="p-6 space-y-4 bg-black/5 rounded-2xl">
          <p class="text-primary font-semibold text-lg leading-7">Reviews</p>
          <div class="flex items-center gap-2">
            <Switch
              :model-value="project.useReviewsPlanLocking"
              label="Enable review plan locking"
              @update:model-value="setReviewsPlanLocking"
            >
              enable review plan locking</Switch
            >
            <FloatingMenu>
              <template #activator="{ show }">
                <button class="block" @click.stop="show">
                  <InformationCircleIcon class="w-5 h-5 text-black/80" />
                </button>
              </template>
              <template #content>
                <div class="w-64 p-2">
                  <p>
                    Enabling review plan locking helps maintain the integrity of
                    the review scope and criteria by preventing unauthorized
                    changes once the review process has started.
                  </p>
                  <div>
                    <a
                      class="text-primary underline"
                      target="_blank"
                      href="/docs/projects.html#citation-style"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </template>
            </FloatingMenu>
          </div>
        </div>

        <div class="p-6 space-y-4 bg-black/5 rounded-2xl text-primary">
          <div class="flex justify-between">
            <div class="flex items-center gap-2">
              <TrashIcon class="w-5 h-5 text-red-500" />
              <p class="font-semibold text-lg leading-none">Delete project</p>
            </div>
            <button
              v-tooltip="
                deleteProjectConfirmation === CheckboxState.uncheked
                  ? 'Please check the box to confirm project deletion'
                  : ''
              "
              :class="{
                'opacity-40 cursor-not-allowed':
                  deleteProjectConfirmation === CheckboxState.uncheked,
              }"
              class="bg-red-500 text-white px-4 py-2 hover:brightness-95 rounded-lg"
              @click.stop="deleteProject(project.id!)"
            >
              Delete Project
            </button>
          </div>
          <div class="bg-white p-4 rounded-lg">
            <div class="flex gap-2">
              <ExclamationCircleIcon class="w-5 h-5 text-red-500" />
              <div>
                <p class="font-bold text-sm leading-[150%]">
                  You are deleting this project
                </p>
                <span class="text-black/80 font-normal text-xs leading-[150%]"
                  >This action deletes the project {{ project.name }} and
                  everything it contains. There is no going back.</span
                >
              </div>
            </div>
          </div>
          <div class="flex gap-2 items-center">
            <button @click.stop="toggleDeleteProjectConfirmation">
              <Checkbox v-model="deleteProjectConfirmation" />
            </button>
            <p class="text-black">I confirm project deletion</p>
          </div>
        </div>
      </div>
    </Scrollbar>
  </div>
</template>

<script setup lang="ts">
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import useCslStyles from '@app/composables/use-cslStyles'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import useProjects from '@app/views/Projects/use-projects'
import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { HttpException } from '@core/exceptions/http.exception'
import { hideAll } from 'tippy.js'
import { computed, ref } from 'vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import ExclamationCircleIcon from '@app/components/Icons/ExclamationCircleIcon.vue'
import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import Scrollbar from '@app/components/Global/Scrollbar.vue'
import NewAsyncTextInput from '../../NewInputs/NewAsyncTextInput.vue'
import Switch from '@app/components/Global/Switch.vue'
import useModalManager from '@app/components/Global/Modal/modal-manager'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

const props = defineProps<{
  project: Project
}>()

const deleteProjectConfirmation = ref<CheckboxState>(CheckboxState.uncheked)

const projectNameInput = ref<InstanceType<typeof NewAsyncTextInput>>()
const contactForIncidentReportingInput =
  ref<InstanceType<typeof NewAsyncTextInput>>()

const cslStyles = useCslStyles()

const cslStyleListItems = computed(() =>
  cslStyles.list.value
    .map((s) => {
      const text = s.charAt(0).toUpperCase() + s.replaceAll('-', ' ').slice(1)
      return { text: text.replaceAll('-', ' '), value: s }
    })
    .sort((a, b) => a.text.localeCompare(b.text)),
)

const internalProjectName = ref(props.project.name ?? '')
const internalContactForComplaint = ref(
  props.project.contactForComplaintEmail ?? '',
)

const selectedCslStyle = ref(props.project.cslStyle)

const loading = useLoading()
const snackbar = useSnackbar()
const projects = useProjects()

async function updateProjectName(projectId: Id, name: string): Promise<void> {
  try {
    projectNameInput.value?.startLoading()
    await projects.updateName(projectId, name)
    internalProjectName.value = name
    projectNameInput.value?.displaySuccess()
  } finally {
    projectNameInput.value?.stopLoading()
  }
}

async function updateSupevisorEmail(
  projectId: Id,
  email: string,
): Promise<void> {
  try {
    contactForIncidentReportingInput.value?.startLoading()
    await projects.updateSupevisorEmail(projectId, email)
    contactForIncidentReportingInput.value?.displaySuccess()
    internalContactForComplaint.value = email
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    contactForIncidentReportingInput.value?.stopLoading()
  }
}

async function updateCslStyle(style?: unknown) {
  if (!style || typeof style !== 'string') return
  try {
    loading.start()
    selectedCslStyle.value = style
    await projects.updateCslStyle(props.project.id!, style)
    snackbar.show(SnackbarState.SUCCESS, 'Citation style updated')
  } catch (e) {
    const error = e as HttpException
    selectedCslStyle.value = props.project.cslStyle
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const modalManager = useModalManager()

async function deleteProject(projectId: Id) {
  if (deleteProjectConfirmation.value !== CheckboxState.checked) return
  loading.start()
  try {
    await projects.deleteProject(projectId)
    hideAll()
    modalManager.hideAllModals()
    deleteProjectConfirmation.value = CheckboxState.uncheked
    snackbar.show(SnackbarState.SUCCESS, 'Project deleted successfully')
  } catch (e) {
    snackbar.error('an error has occured')
    throw e
  } finally {
    loading.stop()
  }
}

function toggleDeleteProjectConfirmation() {
  if (deleteProjectConfirmation.value === CheckboxState.checked)
    deleteProjectConfirmation.value = CheckboxState.uncheked
  else deleteProjectConfirmation.value = CheckboxState.checked
}

async function setReviewsPlanLocking(value: boolean) {
  try {
    loading.start()
    await projects.setReviewsPlanLocking(props.project.id!, value)
    snackbar.success('Review plan locking updated')
  } catch (e) {
    snackbar.error('an error has occured')
    throw e
  } finally {
    loading.stop()
  }
}
</script>
