import { ImportSourceType } from '../types/import-source-type.type'

export class ImportSource {
  id: string
  type: ImportSourceType
  name: string
  description?: string
  url?: string
  order: number

  constructor(data: ImportSource) {
    this.id = data.id
    this.type = data.type
    this.name = data.name
    this.description = data.description
    this.url = data.url
    this.order = data.order ?? 0
  }
}
