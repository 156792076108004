<template>
  <FloatingMenu ref="floatingMenu" @hide="clearSearch">
    <template #activator="{ show }">
      <StudyActionButton
        v-tooltip="study.isDuplicate ? 'Ctrl+click to remove duplication' : ''"
        :is-loading="isLoading"
        :state="
          isParentStudy
            ? ButtonState.disabled
            : !study.isDuplicate
              ? ButtonState.inactive
              : ButtonState.violet
        "
        :is-review-read-only="review.isReviewReadonly.value"
        :class="{ 'border-violet-600 border-4': study.maybeDuplicate }"
        @click.stop="!study.isDuplicate ? show() : ''"
        @click.ctrl.stop="emit('markAsNotDuplicate')"
      >
        Duplicate
      </StudyActionButton>
    </template>
    <template #content>
      <div class="w-[500px]" @click.stop="">
        <div v-if="study.maybeDuplicate" class="flex items-stretch">
          <button
            class="py-2 px-4 w-full text-left hover:bg-gray-200"
            @click="
              () =>
                study.maybeParentStudyId &&
                markAsDuplicate(study.maybeParentStudyId)
            "
          >
            <span class="font-bold">{{ study.maybeParentStudyId }} </span>
            {{ maybeParentStudyId?.title }}
          </button>
          <button
            class="px-4 hover:bg-gray-200 flex items-center"
            @click="markAsNotDuplicate"
          >
            <XIcon class="w-5 h-5" />
          </button>
        </div>
        <div v-else>
          <TextInput
            v-model="searchQuery"
            type="text"
            placeholder="Specify parent record"
            clearable
          />
          <div class="block divide-y max-h-80 overflow-y-auto">
            <button
              v-for="(parentStudy, index) in filteredStudies"
              :key="parentStudy.id + index"
              v-tooltip="parentStudy.title"
              class="truncate w-full px-4 py-2 text-left hover:bg-gray-200"
              :class="[index % 2 === 0 ? 'bg-gray-100' : 'bg-white']"
              @click="markAsDuplicate(parentStudy.id)"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="inline-block mr-2 font-bold"
                v-html="applyHighlights(parentStudy.id.toString(), searchTerms)"
              >
              </span>
              <span
                v-html="
                  parentStudy.title
                    ? applyHighlights(parentStudy.title, searchTerms)
                    : ''
                "
              ></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </FloatingMenu>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ButtonState } from '@app/types'
import { applyHighlights } from '@app/utils/words-highlighter'
import { ReviewKey } from '@app/views/Review/use-review'
import { Study } from '@core/domain/models/study.model'
import { Id } from '@core/domain/types/id.type'
import { computed, ref } from 'vue'
import StudyActionButton from '../StudyActionButton.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { doesStudyContainSearchTerms } from '@app/utils/studyFiltering'
import { useDebounce } from '@vueuse/core'
import XIcon from '@app/components/Icons/XIcon.vue'

const floatingMenu = ref()
const review = injectStrict(ReviewKey)
const searchQuery = ref('')
const debouncedSearchQuery = useDebounce(searchQuery, 600)

const isParentStudy = computed(() => {
  return (
    review.parentStudyIds.value.has(props.study.id) ||
    review.maybeParentStudyIds.value.has(props.study.id)
  )
})

const props = defineProps<{
  study: Study
  isLoading?: boolean
}>()

const searchTerms = computed(() => {
  return debouncedSearchQuery.value
    .split(';')
    .map((st) => st.trim().toLowerCase())
    .filter((st) => !!st)
})

const emit = defineEmits<{
  (e: 'markAsDuplicate', parentStudyId: Id): void
  (e: 'markAsNotDuplicate'): void
}>()

const filteredStudies = computed(() => {
  if (!debouncedSearchQuery.value) return []
  return review.entity.value.studies.filter((a) => {
    return (
      a.id !== props.study.id &&
      !a.isDuplicate &&
      doesStudyContainSearchTerms({ study: a, terms: searchTerms.value })
    )
  })
})

const maybeParentStudyId = computed(() => {
  return review.entity.value.studies.find(
    (s) => s.id === props.study.maybeParentStudyId,
  )
})

function markAsDuplicate(parentStudyId: Id) {
  emit('markAsDuplicate', parentStudyId)
  floatingMenu?.value.hide()
}

function markAsNotDuplicate() {
  emit('markAsNotDuplicate')
  floatingMenu?.value.hide()
}
function clearSearch() {
  searchQuery.value = ''
}
</script>
