import { StudyState } from '@app/types'
import { Study } from '@core/domain/models/study.model'

/**
 * Filters an array of `Study` objects based on specified search tags.
 *
 * @param params - The parameters for the function.
 *   - `studies`: The array of studies to filter.
 *   - `searchTags`: An array of search tags to filter the studies. Optional, defaults to an empty array.
 *   - `ignoreAbstract`: A flag to ignore the abstracts of the studies during the filtering process. Optional, defaults to `false`.
 * @returns The filtered array of studies.
 *
 * This function filters the given array of studies by matching the provided search tags against various fields of each study, such as title, abstract, PDF content, and authors. The search is case-insensitive. If no search tags are provided, the original array of studies is returned. The `ignoreAbstract` flag allows excluding the abstracts from the search.
 */
export function doesStudyContainSearchTerms({
  study,
  terms: searchTerms = [],
  ignoreAbstract = false,
}: {
  study: Study
  terms?: string[]
  ignoreAbstract?: boolean
}): boolean {
  if (searchTerms.length <= 0) return true
  const searchTagsRegexString =
    searchTerms.map((st) => `(?=.*${st})`).join('') + '.*'
  const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')

  const searchableString =
    study.title?.toLocaleLowerCase() +
    ' ' +
    (!ignoreAbstract ? study.abstract?.toLocaleLowerCase() : ' ') +
    study.id.toString() +
    ' ' +
    study.publishYear?.toString() +
    ' ' +
    study.pdfContent?.toLocaleLowerCase() +
    ' ' +
    (study.authors &&
      study.authors.length > 0 &&
      study.authors.join(' ').toLocaleLowerCase())
  return searchRegex.test(searchableString)
}

/**
 * Filters an array of `Study` objects based on their state.
 *
 * @param params - The parameters for the function.
 *   - `studies`: The array of studies to filter.
 *   - `filters`: An object representing the filters to apply, keyed by `StudyState` with boolean values.
 * @returns The filtered array of studies.
 *
 * This function filters the given array of studies based on their state, using the provided filters. The filters object keys correspond to different study states, and their boolean values indicate whether to include studies in that state. The function supports filtering by multiple states simultaneously. If the `FAVOURITE` state is true, it also includes studies marked as favorites.
 */
export function doesStudyContainState({
  study,
  states,
}: {
  study: Study
  states: StudyState[]
}): boolean {
  return states.some(
    (f) =>
      f === study.state || (f === StudyState.FAVOURITE && study.isFavorite),
  )
}
