<template>
  <teleport to="#toolbar">
    <div class="w-full px-10 py-2">
      <div class="inline-flex text-xl font-medium leading-5 py-7 pr-6">
        Users
      </div>
      <CreateUsersFloatingMenu />
    </div>
  </teleport>
  <div class="max-w-5xl container pl-10 py-8">
    <table class="w-full" aria-describedby="users table">
      <thead>
        <tr>
          <th
            v-for="header in ['email', 'role', 'status']"
            :key="'header-' + header"
            class="text-left"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users.users.value" :key="'user-' + user.id">
          <td class="py-4">
            <div class="flex gap-2">
              <EditUserFloatingMenu
                :data="{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  role: user.role,
                  userId: user.id!,
                }"
              />
              {{ user.email }}
            </div>
          </td>
          <td class="py-4 px-3">
            {{ user.role === Role.ADMIN ? 'Admin' : 'User' }}
          </td>
          <td class="py-4 px-2">
            <FloatingMenu>
              <template #activator="{ show }">
                <Switch
                  :model-value="!user.isDisabled"
                  :class="[
                    !user.isDisabled ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                  @click="show"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      !user.isDisabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </template>
              <template #content="{ hide }">
                <div
                  class="p-4 w-96 text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                >
                  <div
                    v-if="!isBillingDisabled"
                    class="rounded-md bg-blue-50 p-4"
                  >
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <InformationCircleIcon
                          class="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p
                          v-if="!user.isDisabled"
                          class="text-sm text-blue-700"
                        >
                          You are disabling a user from your plan.<br />
                          <span class="font-bold">WARNING</span>: Enabling the
                          user at any time will add a new user to your plan.
                        </p>
                        <p v-else class="text-sm text-blue-700">
                          You are restoring a disabled user. You will be billed
                          19.90€ per billing cycle.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="rounded-md bg-blue-50 p-4">
                    <div class="flex">
                      <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p
                          v-if="!user.isDisabled"
                          class="text-sm text-blue-700"
                        >
                          Are you sure you want to disable this user?<br />
                        </p>
                        <p v-else class="text-sm text-blue-700">
                          Are you sure you want to enable this user?
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2">
                    <button
                      class="bg-neutral-500 text-white py-1 px-2 mr-1 hover:brightness-110"
                      @click="hide"
                    >
                      cancel
                    </button>
                    <button
                      class="bg-red-600 text-white py-1 px-2 hover:brightness-125"
                      @click="toggleUserState(user.id!, hide)"
                    >
                      {{ user.isDisabled ? 'Enable' : 'Disable' }}
                    </button>
                  </div>
                </div>
              </template>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts" setup>
import CreateUsersFloatingMenu from '@app/views/Users/CreateUsersFloatingMenu.vue'
import useUsers from '@app/composables/use-users'
import { Role } from '@core/domain/types/role.type'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import EditUserFloatingMenu from './Users/EditUserFloatingMenu.vue'
import { Switch } from '@headlessui/vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { inject } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/24/solid'

const isBillingDisabled = inject('isBillingDisabled')

const snackbar = useSnackbar()
const loading = useLoading()
const users = useUsers()
users.refresh()

async function toggleUserState(userId: string, cb: () => void) {
  const user = users.users.value.find((u) => u.id === userId)
  try {
    loading.start()
    if (!user?.isDisabled) {
      await users.disableUser(userId)
      snackbar.show(
        SnackbarState.SUCCESS,
        'user marked as disabled successfully',
      )
    } else {
      await users.enableUser(userId)
      snackbar.show(
        SnackbarState.SUCCESS,
        'user marked as enabled successfully',
      )
    }
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}
</script>
