<template>
  <button :disabled="isReadonly" @click.stop="emit('toggleFavorite')">
    <StarIcon
      class="w-6 h-6 stroke-1"
      :class="[
        study.isFavorite
          ? 'fill-amber-400 stroke-amber-400'
          : 'fill-transparent stroke-[#A8B0B9]',
      ]"
    />
  </button>
</template>
<script lang="ts" setup>
import StarIcon from '@app/components/Icons/StarIcon.vue'
import { Study } from '@core/domain/models/study.model'

defineProps<{ study: Study; isReadonly: boolean }>()

const emit = defineEmits<(e: 'toggleFavorite') => void>()
</script>
