<template>
  <ImportSourceCard
    :import-source="openFda"
    :tooltip="{
      text: 'Use the Evidence browser extension to import studies from Maude.',
      url: '/docs/reviews.html#maude',
    }"
  >
  </ImportSourceCard>
</template>
<script setup lang="ts">
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'

const builtInImportSources = useBuiltInImportSources()

const openFda = builtInImportSources.findById(BuiltInImportSourceId.OPEN_FDA)!
</script>
