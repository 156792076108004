<template>
  <div
    class="border divide-y divide-black/10 rounded-lg bg-white border-black/30 flex flex-col items-center"
  >
    <template v-for="source in availableBuiltInImportSources" :key="source.id">
      <button
        class="px-4 py-2 w-full text-left flex items-center gap-2"
        :class="{
          'hover:bg-black/5': isPlanUnlocked,
          'cursor-not-allowed': !isPlanUnlocked,
        }"
        @click="isPlanUnlocked ? addImportSourceToPlan(source.id) : ''"
      >
        <img :src="source.iconUrl" class="w-6 h-6" alt="source icon" />
        {{ source.label }}
      </button>
    </template>

    <ExpansionPanel class="w-full" :model-value="isSourcesExpanded">
      <template #activator>
        <button
          v-tooltip.right="
            `<p>Databases consist of sources where searches can be replicated by anyone with access, ensuring consistent and verifiable results.</p><br/>
<div class='flex gap-3'><div>
  <div><strong>Scientific literature database</strong> </div>
  <div><strong>Publisher database</strong> </div>
  <div><strong>Scientific journal</strong> </div>
  <div><strong>Registers</strong> </div>
  <div><strong>Registries</strong> </div>
</div>
<div>
  <div>EXAMPLES: PubMed, Cochrane CDSR</div>
  <div>EXAMPLES: Wiley, MAG Online</div>
  <div>EXAMPLES: Nature, Science</div>
  <div>EXAMPLES: ClinicalTrials.gov, DKRS</div>
  <div>EXAMPLES: MAUDE, BfArM</div>
  </div></div>`
          "
          class="flex justify-between items-center w-full py-2 px-4"
          :class="{
            'hover:bg-black/5': isPlanUnlocked,
            'cursor-not-allowed': !isPlanUnlocked,
          }"
          @click="isPlanUnlocked ? togglePanel('sources') : ''"
        >
          <div>Database</div>
          <InformationCircleIcon class="w-4 h-4 text-primary" />
        </button>
      </template>
      <template #default>
        <NewImportSourceBuilder
          :import-source-type="ImportSourceType.DATABASE"
        />
      </template>
    </ExpansionPanel>

    <ExpansionPanel class="w-full" :model-value="isOtherSourcesExpanded">
      <template #activator>
        <button
          v-tooltip.right="
            `<p>Other methods encompass sources where searches are not easily replicable, often due to varying search algorithms or personalized content delivery.</p><br/>
<div class='flex gap-3'>
            <div>
  <div><strong>Search engine</strong> </div>
  <div><strong>Health organizations</strong> </div>
  <div><strong>Medical associations</strong> </div>
  <div><strong>Standards Organizations</strong> </div>
  <div><strong>Manufacturer websites</strong></div>
  <div><strong>Other online sources</strong> </div>
</div>
<div>
  <div>EXAMPLES: Google Scholar, Google</div>
  <div>EXAMPLES: WHO, NICE</div>
  <div>EXAMPLES: European Society of Cardiology</div>
  <div>EXAMPLES: ISO, IEC</div>
  <div> EXAMPLES: Medtronix, Baxters</div>
  <div> Any online source not included above</div>
  </div>
  </div>`
          "
          class="flex justify-between items-center w-full py-2 px-4"
          :class="{
            'hover:bg-black/5': isPlanUnlocked,
            'cursor-not-allowed': !isPlanUnlocked,
          }"
          @click="isPlanUnlocked ? togglePanel('other-sources') : ''"
        >
          <div>Other source</div>
          <InformationCircleIcon class="w-4 h-4 text-primary" />
        </button>
      </template>
      <template #default>
        <NewImportSourceBuilder
          :import-source-type="ImportSourceType.OTHER_SOURCE"
        />
      </template>
    </ExpansionPanel>
    <button
      v-if="!isCitationSearchActive"
      v-tooltip.right="
        `<p>Document records identified when screening the references of already identified records.</p>`
      "
      class="px-4 py-2 w-full text-left flex items-center justify-between gap-2"
      :class="{
        'hover:bg-black/5': isPlanUnlocked,
        'cursor-not-allowed': !isPlanUnlocked,
      }"
      @click="
        isPlanUnlocked
          ? addImportSourceToPlan(BuiltInImportSourceId.CITATION_SEARCH)
          : ''
      "
    >
      <div>Citation search</div>
      <InformationCircleIcon class="w-4 h-4 text-primary" />
    </button>

    <button
      v-if="!isHandSearchActive"
      v-tooltip.right="
        `<p>(not recommended) documents records that have not been captured through systematic searches</p>`
      "
      class="px-4 py-2 w-full text-left flex items-center justify-between gap-2"
      :class="{
        'hover:bg-black/5': isPlanUnlocked,
        'cursor-not-allowed': !isPlanUnlocked,
      }"
      @click="
        isPlanUnlocked
          ? addImportSourceToPlan(BuiltInImportSourceId.HAND_SEARCH)
          : ''
      "
    >
      <div>Hand search</div>
      <InformationCircleIcon class="w-4 h-4 text-primary" />
    </button>
  </div>
</template>
<script setup lang="ts">
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'

import { computed, ref } from 'vue'
import NewImportSourceBuilder from './NewImportSourceBuilder.vue'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { DevToolboxKey } from '@app/injectionKeys'

const loading = useLoading()
const snackbar = useSnackbar()
const builtInImportSources = useBuiltInImportSources()

const review = injectStrict(ReviewKey)
const { isCuttingEdge } = injectStrict(DevToolboxKey)

const activeExpansionPanel = ref<'sources' | 'other-sources' | ''>('')

const isSourcesExpanded = computed(
  () => activeExpansionPanel.value === 'sources',
)
const isOtherSourcesExpanded = computed(
  () => activeExpansionPanel.value === 'other-sources',
)

const isPlanUnlocked = computed(() => !review.isPlanReadonly.value)

async function addImportSourceToPlan(importSourceId: BuiltInImportSourceId) {
  loading.start()
  try {
    await review.addImportSourceToPlan(importSourceId)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Import source successfully added to plan',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
const availableBuiltInImportSources = computed(() => {
  const builtInSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!.name,
      id: BuiltInImportSourceId.PUBMED,
      iconUrl: '/sources-icons/pubmed.png',
    },
    {
      label: builtInImportSources.findById(
        BuiltInImportSourceId.GOOGLE_SCHOLAR,
      )!.name,
      id: BuiltInImportSourceId.GOOGLE_SCHOLAR,
      iconUrl: '/sources-icons/google-scholar.png',
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.DIMENSIONS_AI)!
        .name,
      id: BuiltInImportSourceId.DIMENSIONS_AI,
      iconUrl: '/sources-icons/dimensions-ai.png',
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!
        .name,
      id: BuiltInImportSourceId.COCHRANE,
      iconUrl: '/sources-icons/cochrane.png',
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!.name,
      id: BuiltInImportSourceId.MAUDE,
      iconUrl: '/sources-icons/maude.png',
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.OPEN_ALEX)!
        .name,
      id: BuiltInImportSourceId.OPEN_ALEX,
      iconUrl: '/sources-icons/openalex.png',
    },

    {
      label: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!.name,
      id: BuiltInImportSourceId.ICTRP,
      iconUrl: '/sources-icons/ictrp.png',
    },

    ...(isCuttingEdge
      ? [
          {
            label: builtInImportSources.findById(BuiltInImportSourceId.PMC)!
              .name,
            id: BuiltInImportSourceId.PMC,
            iconUrl: '/sources-icons/pmc.png',
          },
          {
            label: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!
              .name,
            id: BuiltInImportSourceId.MDCG,
            iconUrl: '/sources-icons/mdcg.png',
          },
        ]
      : []),
  ]

  const reviewImportSources =
    review.entity.value.plan?.importPlan.importSources ?? []

  return builtInSources.filter(
    (source) =>
      !reviewImportSources.some(
        (reviewSource) => reviewSource.id === source.id,
      ),
  )
})

const isHandSearchActive = computed(() => {
  return (
    review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === BuiltInImportSourceId.HAND_SEARCH,
    ) ?? false
  )
})

const isCitationSearchActive = computed(() => {
  return (
    review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === BuiltInImportSourceId.CITATION_SEARCH,
    ) ?? false
  )
})

function togglePanel(panel: 'sources' | 'other-sources') {
  if (activeExpansionPanel.value === panel) {
    activeExpansionPanel.value = ''
  } else {
    activeExpansionPanel.value = panel
  }
}
</script>
