import { ref } from 'vue'

export default () => {
  const isScreeningStageActive = ref(true)

  const isAppraisalStageActive = ref(false)

  const isDataExtractionStageActive = ref(false)

  function toggleScreeningStage() {
    isScreeningStageActive.value = !isScreeningStageActive.value
  }

  function toggleAppraisalStage() {
    isAppraisalStageActive.value = !isAppraisalStageActive.value
  }

  function toggleDataExtractionStage() {
    isDataExtractionStageActive.value = !isDataExtractionStageActive.value
  }

  return {
    toggleScreeningStage,
    toggleAppraisalStage,
    toggleDataExtractionStage,
    isScreeningStageActive,
    isAppraisalStageActive,
    isDataExtractionStageActive,
  }
}
