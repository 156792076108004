import { UsersServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { UsersService } from '@core/application/users.service'
import { User } from '@core/domain/models/user.model'
import { Role } from '@core/domain/types/role.type'
import { ref } from 'vue'

const users = ref<User[]>([])
export default () => {
  const usersService: UsersService = injectStrict(UsersServiceKey)!

  const create = async (data: {
    email: string
    role: Role
    password: string
    firstName: string
    lastName: string
  }) => {
    const user = await usersService.create(data)
    users.value.push(user)
    return user
  }

  const refresh = async () => {
    const fetchedUsers = await usersService.find()
    users.value = fetchedUsers
  }

  async function updateCurrentUserFullName(data: {
    firstName: string
    lastName: string
  }) {
    await usersService.updateCurrentUserFullName(data)
  }

  async function enableUser(userId: string): Promise<void> {
    await usersService.enableUser(userId)
    const updatedUserIndex = users.value.findIndex((u) => u.id === userId)
    users.value[updatedUserIndex].isDisabled = false
  }

  async function edit(data: {
    userId: string
    firstName: string
    lastName: string
    role: Role
  }): Promise<void> {
    await usersService.edit(data)
    const updatedUserIndex = users.value.findIndex((u) => u.id === data.userId)
    users.value[updatedUserIndex] = new User({
      ...users.value[updatedUserIndex],
      ...data,
    })
  }

  async function disableUser(userId: string): Promise<void> {
    await usersService.disableUser(userId)
    const updatedUserIndex = users.value.findIndex((u) => u.id === userId)
    users.value[updatedUserIndex].isDisabled = true
  }

  return {
    users: users,

    create,
    refresh,
    edit,
    updateCurrentUserFullName,
    enableUser,
    disableUser,
  }
}
