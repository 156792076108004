import { StudyState } from '@app/types'
import { Review } from '@core/domain/models/review.model'
import { Search } from '@core/domain/models/search.model'
import { Study } from '@core/domain/models/study.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'

type StudyScreening = {
  id: number
  title: string
  abstract: string
  stateText: string
  fillColor: string
}

export type SearchScreeningResult = {
  empty: boolean
  searchNumber: number
  source: string
  studies: StudyScreening[]
}

export function generateReviewScreeningResults(review: Review) {
  let searchIndex = 0
  const searchesResults: SearchScreeningResult[] = []

  const _getStudyStateText = (study: Study, studyState: StudyState) => {
    let studyStateText = '-'
    if (studyState === StudyState.DUPLICATE) {
      studyStateText = 'Duplicate'
    } else if (studyState === StudyState.INCLUDED) {
      studyStateText = 'Included'
    } else if (
      study.titleAndAbstractScreening &&
      study.titleAndAbstractScreening !== 'Included' &&
      review.plan?.screeningPlan?.titleAndAbstractCriteria?.length === 0
    ) {
      studyStateText = 'Excluded'
    } else if (
      study.titleAndAbstractScreening &&
      study.titleAndAbstractScreening !== 'Included'
    ) {
      studyStateText =
        'Excluded on title and abstract: ' + study.titleAndAbstractScreening
    } else if (
      study.fullTextScreening &&
      study.fullTextScreening != 'Included'
    ) {
      studyStateText = 'Excluded on full text: ' + study.fullTextScreening
    }
    return studyStateText
  }

  const _generateSearchScreeningResult = (
    search: Search,
    searchIndex: number,
  ) => {
    let empty = false
    const studies: StudyScreening[] = []
    const source = search.source.name ?? '-'

    if (search.studies.length === 0) {
      empty = true
    } else {
      search.studies.forEach((s) => {
        const studyState = s.state
        const studyStateText = _getStudyStateText(s, studyState)
        studies.push({
          id: s.id,
          abstract: s.abstract
            ? s.abstract
                .replaceAll(new RegExp(/<[^>]+>/gm), '')
                .replaceAll(/\s+/g, ' ')
                .trim()
                .replace(/^Abstract(.+?)/m, '')
            : 'not available',
          stateText: studyStateText,
          title: s.title ? s.title : '-',
          fillColor: studyStateText === 'Included' ? 'E2EFD8' : '',
        })
      })
    }

    searchesResults.push({
      empty: empty,
      source: source,
      searchNumber: searchIndex,
      studies: studies,
    })
    return searchesResults
  }

  const sortedSearchs = [...review.searches!]

  sortedSearchs
    .filter((c) => [ImportSourceType.DATABASE].includes(c.source.type))
    .forEach((search) => {
      searchIndex += 1

      _generateSearchScreeningResult(search, searchIndex)
    })
  sortedSearchs
    .filter((c) => [ImportSourceType.OTHER_SOURCE].includes(c.source.type))
    .forEach((search) => {
      searchIndex += 1

      _generateSearchScreeningResult(search, searchIndex)
    })

  sortedSearchs
    .filter((c) => c.source.type === ImportSourceType.HAND_SEARCH)
    .forEach((search) => {
      searchIndex += 1
      _generateSearchScreeningResult(search, searchIndex)
    })

  sortedSearchs
    .filter((c) => c.source.type === ImportSourceType.CITATION_SEARCH)
    .forEach((search) => {
      searchIndex += 1
      _generateSearchScreeningResult(search, searchIndex)
    })

  return searchesResults.sort((a, b) => a.searchNumber - b.searchNumber)
}
