import { Id } from '@core/domain/types/id.type'
import { Study } from '@core/domain/models/study.model'
import * as CLS from 'citeproc'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
type StudyObject = {
  type: string
  id: number
  title?: string
  abstract?: string
  'container-title'?: string
  author: string | Array<{ family: string; given: string }>
  issued?: {
    'date-parts': string[][]
  }
  volume?: string
  issue?: string
  page?: string
}

export async function generateCitation(
  studies: Study[],
  cslStyleName: string,
): Promise<{
  findByStudyId: (studyId: Id) => { inText: string; fullText: string }
}> {
  const citations: { [id: Id]: StudyObject } = {}
  for (const study of studies) {
    citations[study.id] = {
      type: 'study-journal',
      id: study.id,
      title: study.title,
      abstract: study.abstract
        ?.replaceAll(new RegExp(/<[^>]*>/gm), '')
        .replaceAll(/\s+/g, ' ')
        .trim()
        .replace(/^Abstract(.*?)/m, ''),
      'container-title': study.journal,
      author:
        study.studyId && study.search?.source.id === BuiltInImportSourceId.MAUDE
          ? study.studyId
          : study.authors?.map((au) => {
              const [familyName, givenName] = au.split(', ')
              return {
                family: familyName,
                given: givenName,
              }
            }),
      ...(study.publishYear && {
        issued: {
          'date-parts': [[study.publishYear]],
        },
      }),
      volume: study.volumeNumber,
      issue: study.issueNumber,
      page: study.pagesNumber,
    }
  }
  const locale = await fetch('/csl/locale/locales-en-US.xml').then((r) =>
    r.text(),
  )
  const style = await fetch(`/csl/styles/${cslStyleName}.csl`).then((r) =>
    r.text(),
  )

  const itemsIds = studies.map((a) => a.id)
  const citeproc = new CLS.Engine(
    {
      retrieveLocale: function () {
        return locale
      },
      retrieveItem: function (id: Id) {
        return citations[id]
      },
    },
    style,
  )
  citeproc.setOutputFormat('text')
  citeproc.updateItems(itemsIds)

  const [params, result] = await citeproc.makeBibliography()

  const citationClusterPromises = studies.map(async (study) => {
    const citationObject = citations[study.id]
    return citeproc.makeCitationCluster([citationObject])
  })
  const citationClusters = await Promise.all(citationClusterPromises)

  const studyIdToClusterMap: { [id: string]: unknown } = {}
  studies.forEach((study, index) => {
    studyIdToClusterMap[study.id] = citationClusters[index]
  })

  return {
    findByStudyId(studyId: Id) {
      const index = (params.entry_ids as [[string]]).findIndex(
        ([id]: [string]) => parseInt(id) === studyId,
      )
      return {
        fullText: result[index],
        inText: (studyIdToClusterMap[studyId] as string) ?? 'ref',
      }
    },
  }
}
