import { Id } from '../types/id.type'
import { Study } from './study.model'
import { FileMeta } from './file-meta.model'
import { ImportSource } from './import-source.model'

export class Search {
  id?: Id
  personUndertakingSearch: string
  source: ImportSource
  searchDate?: string
  studies: Study[]
  citationFiles: FileMeta[]
  searchQuery?: string
  queryFile?: FileMeta
  url?: string
  parentStudyId?: Id
  datefilter?: {
    start: string
    end: string
  }
  filters?: string
  queryTranslation?: string
  constructor(data: Partial<Search> & { source: ImportSource }) {
    this.id = data.id
    this.personUndertakingSearch = data.personUndertakingSearch ?? ''
    this.source = data.source
    this.searchDate = data.searchDate ?? undefined
    this.studies = data.studies ?? []
    this.citationFiles = data.citationFiles as FileMeta[]
    this.searchQuery = data.searchQuery ?? ''
    this.queryFile = data.queryFile as FileMeta
    this.url = data.url ?? ''
    this.parentStudyId = data.parentStudyId
    this.datefilter = data.datefilter
    this.filters = data.filters
    this.queryTranslation = data.queryTranslation
  }
}
