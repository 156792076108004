<template>
  <svg ref="chartRef"></svg>
</template>

<script lang="ts" setup generic="T">
import { ref, onMounted, watch, computed } from 'vue'
import * as d3 from 'd3'

interface DataItem {
  id: T
  label: string
  value: number
  color: string
  calloutColor: string
  order: number
}

interface LabelPosition {
  x: number
  y: number
  width: number
  height: number
  data: d3.PieArcDatum<DataItem>
  index: number
}

const props = defineProps<{
  items: DataItem[]
}>()

const items = computed(() => props.items.filter((item) => item.value > 0))

const selectedItem = defineModel<T | null>('selected', { default: null })

const chartRef = ref<SVGSVGElement | null>(null)

const createChart = (): void => {
  if (!chartRef.value) return

  d3.select(chartRef.value).selectAll('*').remove()

  const width = 700
  const height = 600
  const padding = 20
  const radius = Math.min(width, height) / 3.5 - padding * 2

  const svg = d3
    .select(chartRef.value)
    .attr('width', width)
    .attr('height', height)

  const defs = svg.append('defs')
  const pattern = defs
    .append('pattern')
    .attr('id', 'quarantine-stripes')
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('width', 10)
    .attr('height', 10)
    .attr('patternTransform', 'rotate(45)')

  pattern
    .append('rect')
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#f2e42719')

  pattern
    .append('rect')
    .attr('width', 5)
    .attr('height', 10)
    .attr('fill', '#00000019')

  const chartGroup = svg
    .append('g')
    .attr('transform', `translate(${width / 2}, ${height / 2})`)

  const pie = d3
    .pie<DataItem>()
    .value((d) => d.value)
    .sort((d) => d.order)

  const arc = d3
    .arc<d3.PieArcDatum<DataItem>>()
    .innerRadius(0)
    .outerRadius((d) =>
      d.data.id === selectedItem.value ? radius * 1.1 : radius,
    )

  const outerArc = d3
    .arc<d3.PieArcDatum<DataItem>>()
    .innerRadius(radius * 1.5)
    .outerRadius(radius * 1.5)

  const arcs = chartGroup
    .selectAll('arc')
    .data(pie(items.value))
    .enter()
    .append('g')
    .attr('class', 'arc')

  arcs
    .append('path')
    .attr('d', (d) => arc(d) || '')
    .attr('fill', (d) => d.data.color)
    .style('cursor', 'pointer')
    .style('stroke', 'white')
    .style('stroke-width', 4)
    .on(
      'mouseover',
      function (this: SVGPathElement, _, d: d3.PieArcDatum<DataItem>) {
        if (d.data.id !== selectedItem.value) {
          const enlargedArc = d3
            .arc<d3.PieArcDatum<DataItem>>()
            .innerRadius(0)
            .outerRadius(radius * 1.1)

          d3.select(this)
            .transition()
            .duration(200)
            .attr('d', enlargedArc(d) || '')
        }
      },
    )
    .on(
      'mouseout',
      function (this: SVGPathElement, _, d: d3.PieArcDatum<DataItem>) {
        if (d.data.id !== selectedItem.value) {
          d3.select(this)
            .transition()
            .duration(200)
            .attr('d', arc(d) || '')
        }
      },
    )
    .on('click', function (_: MouseEvent, d: d3.PieArcDatum<DataItem>) {
      selectedItem.value = selectedItem.value === d.data.id ? null : d.data.id
    })

  const labelPositions: LabelPosition[] = arcs.data().map((d, index) => {
    const pos = outerArc.centroid(d)
    const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2
    pos[0] = radius * 1.5 * (midAngle < Math.PI ? 1 : -1)
    return {
      x: pos[0],
      y: pos[1],
      width: 100,
      height: 20,
      data: d,
      index: index,
    }
  })

  const checkOverlap = (a: LabelPosition, b: LabelPosition): boolean => {
    return !(
      a.x + a.width < b.x ||
      a.x > b.x + b.width ||
      a.y + a.height < b.y ||
      a.y > b.y + b.height
    )
  }

  const adjustLabelPositions = (
    positions: LabelPosition[],
  ): LabelPosition[] => {
    const adjusted = [...positions]
    const verticalSpacing = 0

    adjusted.sort((a, b) => a.y - b.y)

    for (let i = 1; i < adjusted.length; i++) {
      const prevLabel = adjusted[i - 1]
      const currLabel = adjusted[i]

      if (
        checkOverlap(prevLabel, currLabel) ||
        currLabel.y < prevLabel.y + prevLabel.height + verticalSpacing
      ) {
        currLabel.y = prevLabel.y + prevLabel.height + verticalSpacing
      }
    }

    adjusted.sort((a, b) => a.index - b.index)

    return adjusted
  }

  let finalPositions = labelPositions
  for (let i = 0; i < 10; i++) {
    const newPositions = adjustLabelPositions(finalPositions)
    if (JSON.stringify(newPositions) === JSON.stringify(finalPositions)) {
      break
    }
    finalPositions = newPositions
  }

  arcs
    .append('text')
    .attr('dy', '.35em')
    .html((d) => `${d.data.label}: ${d.data.value}`)
    .attr('transform', (_, i) => {
      const pos = finalPositions[i]
      return `translate(${pos.x},${pos.y})`
    })
    .style('fill', (d) => d.data.calloutColor)
    .style('text-anchor', (d) => {
      const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2
      return midAngle < Math.PI ? 'start' : 'end'
    })

  arcs
    .append('polyline')
    .attr('stroke', (d) => d.data.calloutColor)
    .style('fill', 'none')
    .style('pointer-events', 'none')
    .attr('stroke-width', 2)
    .attr('points', (d, i) => {
      const pos = finalPositions[i]
      const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2

      const startPoint = arc.centroid(d)
      const startAngle = Math.atan2(startPoint[1], startPoint[0])
      const overlapFactor = 0.8
      const adjustedStartPoint = [
        Math.cos(startAngle) * radius * overlapFactor,
        Math.sin(startAngle) * radius * overlapFactor,
      ]
      const endPoint = [pos.x + (midAngle < Math.PI ? -10 : 10), pos.y]

      const midRadius = radius * 1.1
      const midPoint = [
        Math.sin(midAngle) * midRadius,
        -Math.cos(midAngle) * midRadius,
      ]
      const cornerPoint = [midPoint[0], endPoint[1]]

      return `${adjustedStartPoint},${cornerPoint},${endPoint}`
    })
}

onMounted(() => {
  createChart()
})

watch(
  [() => items, selectedItem],
  () => {
    createChart()
  },
  { deep: true },
)
</script>
