<template>
  <div class="border p-4 rounded-lg space-y-4 border-primary">
    <div class="flex justify-between items-start">
      <div class="space-y-5 w-full">
        <div class="flex items-center justify-between gap-2 w-full">
          <span
            class="rounded-md px-2 py-1 text-xs"
            :class="{
              'bg-green-500 text-white':
                importSource.type === ImportSourceType.DATABASE,
              'bg-blue-500 text-white':
                importSource.type === ImportSourceType.OTHER_SOURCE,
              'bg-orange-500 text-white':
                importSource.type === ImportSourceType.HAND_SEARCH,
              'bg-purple-600 text-white':
                importSource.type === ImportSourceType.CITATION_SEARCH,
            }"
          >
            {{ importSource.type.replaceAll('_', ' ') }}
          </span>
          <button
            v-if="
              (review.searchesBySource.value[importSource.id]?.length ?? 0) <=
                0 && !review.isPlanReadonly.value
            "
            @click="removeImportSourceFromPlan(importSource.id)"
          >
            <TrashIcon class="w-6 h-6 text-red-600" />
          </button>
        </div>
        <div class="flex items-center gap-2">
          <template v-if="sourceIconUrl">
            <img
              v-show="isLogoValid"
              :key="sourceIconUrl"
              class="w-16"
              :src="sourceIconUrl"
              alt="source logo"
              @load="
                ({ target: imageElement }) =>
                  checkImage(imageElement as HTMLImageElement)
              "
              @error="
                ({ target: imageElement }) =>
                  handleImageError(imageElement as HTMLImageElement)
              "
            />
            <div
              v-show="!isLogoValid"
              class="w-16 h-16 p-3 border-primary/50 border-2 text-primary/80 rounded-full"
            >
              <DatabaseIcon class="w-full h-full" />
            </div>
          </template>
          <div
            v-else
            class="w-16 h-16 p-3 border-primary/50 border-2 text-primary/80 rounded-full"
          >
            <DatabaseIcon class="w-full h-full" />
          </div>
          <div class="flex items-center gap-2">
            {{ importSource.name
            }}<InfoTooltip
              v-if="tooltip?.text"
              :text="tooltip?.text"
              :url="tooltip?.url"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        isSelected && review.searchesBySource.value[importSource.id]?.length > 0
      "
      class="rounded-lg bg-black/5 p-6 space-y-4"
    >
      <table
        aria-describedby="searches tables"
        class="w-full border-collapse border-spacing-7"
      >
        <thead>
          <tr>
            <th class="text-left align-top">
              <div class="p-1">Search</div>
            </th>
            <th class="text-left align-top">
              <div class="p-1">Date</div>
            </th>
            <th class="text-left align-top">
              <div class="p-1">Person</div>
            </th>
            <th
              v-if="importSource.id === BuiltInImportSourceId.CITATION_SEARCH"
              class="text-left align-top"
            >
              <div class="p-1">Parent</div>
            </th>
            <th
              v-if="
                importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
              "
              class="text-left align-top"
            >
              <div class="p-1">Query</div>
            </th>
            <th
              v-if="importSource.id === BuiltInImportSourceId.PUBMED"
              class="text-left align-top"
            >
              <div class="p-1 max-w-[500px]">Search details</div>
            </th>
            <th class="text-left align-top">
              <div
                v-if="
                  importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                  importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
                "
                class="p-1 max-w-[500px]"
              >
                Filters
              </div>
            </th>
            <th class="text-left align-top">
              <div class="p-1">#</div>
            </th>
            <th class="text-left align-top">
              <div class="p-1"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(search, index) in review.searchesBySource.value[
              importSource.id
            ]"
            :key="importSource.id + index"
          >
            <tr class="border-t border-black/20">
              <td class="align-top">
                <div class="p-2">{{ index + 1 }}</div>
              </td>

              <td class="align-top">
                <div class="p-1">
                  {{
                    search.searchDate
                      ? format(
                          new Date(search.searchDate),
                          settings.dateFormat.value,
                        )
                      : '-'
                  }}
                </div>
              </td>
              <td class="align-top">
                <div class="p-1">
                  {{ search.personUndertakingSearch ?? '-' }}
                </div>
              </td>
              <td
                v-if="importSource.id === BuiltInImportSourceId.CITATION_SEARCH"
                class="p-1"
              >
                <div class="p-1">
                  <span
                    v-tooltip="
                      'Title: ' + getStudyById(search.parentStudyId!)?.title
                    "
                    class="flex items-center gap-1 w-fit"
                  >
                    {{ search.parentStudyId ?? '-' }}
                    <InformationCircleIcon class="text-black-20 w-4 h-4" />
                  </span>
                </div>
              </td>
              <td
                v-if="
                  importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                  importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
                "
                class="align-top"
              >
                <div class="p-1">{{ search.searchQuery ?? '-' }}</div>
              </td>
              <td
                v-if="importSource.id === BuiltInImportSourceId.PUBMED"
                class="align-top"
              >
                <div class="p-1 max-w-[500px]">
                  {{
                    search.queryTranslation
                      ? updateEndDateInPubmedQuery(search.queryTranslation)
                      : '-'
                  }}
                </div>
              </td>
              <td class="align-top">
                <div
                  v-if="
                    importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                    importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
                  "
                  class="p-1 max-w-[500px]"
                >
                  {{
                    search.filters
                      ? formatDatesInSearchFilters(search.filters)
                      : '-'
                  }}
                </div>
              </td>
              <td class="align-top">
                <div
                  v-if="review.runningImportsBySearch.value.has(search.id!)"
                  class="p-1"
                >
                  <LoadingIcon />
                </div>
                <div v-else class="p-1">{{ search.studies?.length }}</div>
              </td>
              <td
                v-if="
                  review.entity.value.plan?.lockState !==
                    ReviewLockState.LOCKED &&
                  !review.entity.value.isArchived &&
                  !review.entity.value.isLocked
                "
                class="align-top"
              >
                <div class="p-1">
                  <FloatingMenu>
                    <template #activator="{ show }">
                      <button @click="show">
                        <TrashIcon class="w-6 h-6 text-red-600" />
                      </button>
                    </template>
                    <template #content="{ hide }">
                      <div class="p-4 space-y-2">
                        <div>
                          <p v-if="doesSearchContainScreenedStudies(search)">
                            This search contains screened studies, are you sure
                            you want to delete it?
                          </p>
                          <p v-else>
                            Are you sure you want to delete this search?
                          </p>
                        </div>
                        <div>
                          <button
                            class="bg-neutral-500 hover:bg-neutral-600 text-white py-1 px-2 mr-1"
                            @click.stop="hide"
                          >
                            cancel
                          </button>
                          <button
                            class="bg-red-500 hover:bg-red-600 text-white py-1 px-2"
                            @click.stop="
                              () => {
                                removeSearch(search).then(() => hide())
                              }
                            "
                          >
                            delete
                          </button>
                        </div>
                      </div>
                    </template>
                  </FloatingMenu>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <slot name="bottom" :is-selected="isSelected" />
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { format, parse, isValid } from 'date-fns'
import { useSettings } from '@app/composables/use-settings'
import { Search } from '@core/domain/models/search.model'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { computed, ref } from 'vue'
import { ImportSource } from '@core/domain/models/import-source.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import DatabaseIcon from '@app/components/Icons/DatabaseIcon.vue'
import { watchDebounced } from '@vueuse/core'
import { isUrlValid } from '@app/utils/urlValidation'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import LoadingIcon from '@app/components/Icons/LoadingIcon.vue'
import InfoTooltip from '@app/components/Global/InfoTooltip.vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { updateEndDateInPubmedQuery } from '@app/utils/updateEndDateInPubmedQuery'

const props = defineProps<{
  importSource: ImportSource
  tooltip?: {
    text: string
    url?: string
  }
  iconUrl?: string
}>()

function formatDatesInSearchFilters(searchFilters: string): string {
  const dateMatches = searchFilters.match(/\d{1,2}([/.-])\d{1,2}\1\d{2,4}/g)

  if (dateMatches) {
    const formattedDates = dateMatches.map((dateStr) => {
      const parsedDate = parse(dateStr, 'dd/MM/yyyy', new Date())

      if (isValid(parsedDate)) {
        return {
          original: dateStr,
          formatted: format(parsedDate, settings.dateFormat.value),
        }
      } else {
        return {
          original: dateStr,
          formatted: dateStr,
        }
      }
    })

    return formattedDates.reduce(
      (newText, { original, formatted }) =>
        newText.replace(original, formatted),
      searchFilters,
    )
  }

  return searchFilters
}

function buildSourceIconUrl(sourceUrl?: string): string {
  if (props.iconUrl) return props.iconUrl
  if (!sourceUrl || !isUrlValid(sourceUrl))
    return '/sources-icons/custom-source.png'

  return `https://www.google.com/s2/favicons?domain=${encodeURIComponent(
    sourceUrl,
  )}&sz=64`
}

const sourceIconUrl = ref(buildSourceIconUrl(props.importSource.url ?? ''))

watchDebounced(
  () => props.importSource.url,
  (url) => {
    sourceIconUrl.value = buildSourceIconUrl(url)
  },
  {
    debounce: 500,
    maxWait: 1000,
  },
)

const isLogoValid = ref(true)
const loading = useLoading()
const snackbar = useSnackbar()

const settings = useSettings()
const review = injectStrict(ReviewKey)
const isSelected = computed(() =>
  review.entity?.value?.plan?.importPlan?.importSources?.some(
    (ri) => ri.id === props.importSource.id,
  ),
)
async function removeSearch(search: Search) {
  if (!search) throw new Error('no search selected')
  try {
    loading.start()
    await review.removeSearch(search.id!)
    await review.refresh()
    snackbar.show(SnackbarState.SUCCESS, 'search removed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

function doesSearchContainScreenedStudies(search: Search) {
  return search.studies?.some(
    (s) => s.titleAndAbstractScreening ?? s.fullTextScreening,
  )
}

async function removeImportSourceFromPlan(sourceId: string) {
  try {
    loading.start()
    await review.removeImportSourceFromPlan(sourceId)
    snackbar.show(SnackbarState.SUCCESS, 'source removed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

function checkImage(target: HTMLImageElement) {
  const img = target

  if (img.naturalWidth === 16 && img.naturalHeight === 16) {
    isLogoValid.value = false
  } else {
    isLogoValid.value = true
  }
}

function handleImageError(target: HTMLImageElement) {
  target.src = '/sources-icons/custom-source.png'
}

function getStudyById(id: number) {
  const study = review.entity.value.studies?.find((s) => s.id === id)
  return study
}
</script>
