import { ImportSource } from '@core/domain/models/import-source.model'
import { BuiltInImportSourcesRepository } from '@infrastructure/datasource/built-in-import-sources.repository'

export class BuiltInImportSourcesService {
  constructor(
    private builtInImportSourcesRepository: BuiltInImportSourcesRepository,
  ) {}

  async find(): Promise<ImportSource[]> {
    return this.builtInImportSourcesRepository.find()
  }
}
