import { StudyState } from '@app/types'
import { Id } from '../types/id.type'
import { Attribute } from './attributes.model'
import { FileMeta } from './file-meta.model'
import { Search } from './search.model'
import { INCLUDED } from '../types/included-screening-criterion.type'
import { PeerReviewStatus } from '../types/peerReview.type'

export class Study {
  id: Id
  studyId?: string
  eventType?: string
  parentStudyId?: Id
  maybeParentStudyId?: number
  reviewId: Id
  title?: string
  abstract?: string
  authors: string[]
  doi?: string
  url?: string
  journal?: string
  volumeNumber?: string
  issueNumber?: string
  pagesNumber?: string
  rawData: string
  isFavorite?: boolean
  complaintDate?: Date
  titleAndAbstractScreening?: string
  fullTextScreening: string
  search?: Search
  publishYear?: string
  pdfFile?: FileMeta
  pdfContent?: string
  reference?: string
  appraisal?: { [key: string]: string }
  oxfordLevelOfEvidence?: string
  studyDataExtraction: { [key: string]: string }
  groupDataExtraction: { [key: string]: string; id: string }[]
  dataExtraction: { attributes: Attribute[] }
  peerReviewStatus?: PeerReviewStatus

  constructor(data: Partial<Study>) {
    this.id = data.id ?? 0
    this.studyId = data.studyId
    this.eventType = data.eventType
    this.title = data.title
    this.abstract = data.abstract
    this.authors = data.authors ?? []
    this.doi = data.doi
    this.url = data.url
    this.isFavorite = data.isFavorite ?? false
    this.complaintDate = data.complaintDate
    this.search = data.search
    this.titleAndAbstractScreening = data.titleAndAbstractScreening ?? ''
    this.fullTextScreening = data.fullTextScreening ?? ''
    this.reviewId = data.reviewId ?? 0
    this.parentStudyId = data.parentStudyId
    this.maybeParentStudyId = data.maybeParentStudyId
    this.publishYear = data.publishYear
    this.pdfFile = data.pdfFile
    this.rawData = data.rawData ?? ''
    this.journal = data.journal
    this.appraisal = data.appraisal ?? {}
    this.oxfordLevelOfEvidence = data.oxfordLevelOfEvidence ?? ''
    this.studyDataExtraction = data.studyDataExtraction ?? {}
    this.groupDataExtraction = data.groupDataExtraction ?? []
    this.pagesNumber = data.pagesNumber ?? ''
    this.issueNumber = data.issueNumber ?? ''
    this.volumeNumber = data.volumeNumber ?? ''
    this.pdfContent = data.pdfContent
    this.dataExtraction = data.dataExtraction ?? { attributes: [] }
    this.peerReviewStatus = data.peerReviewStatus ?? PeerReviewStatus.default
  }

  get isDuplicate(): boolean {
    return !!this.parentStudyId
  }

  get maybeDuplicate(): boolean {
    return !!this.maybeParentStudyId
  }
  public static create(data: {
    reviewId: Id
    rawData: string
    title?: string
    abstract?: string
    authors?: string[]
    doi?: string
    url?: string
    search?: Search
    publishYear?: string
    journal?: string
    volumeNumber?: string
    issueNumber?: string
    pagesNumber?: string
    studyId?: string
    eventType?: string
  }) {
    const study = new Study(data)
    if (!data.reviewId) throw new Error('review is required')
    return study
  }

  get DoiLink() {
    return this.doi ? `https://doi.org/${this.doi}` : ''
  }

  get state(): StudyState {
    if (this.isDuplicate) return StudyState.DUPLICATE
    if (this.fullTextScreening === INCLUDED) return StudyState.INCLUDED
    if (
      (this.titleAndAbstractScreening &&
        this.titleAndAbstractScreening != INCLUDED) ||
      (this.fullTextScreening && this.fullTextScreening !== INCLUDED)
    )
      return StudyState.EXCLUDED
    if (
      !this.abstract ||
      !this.title ||
      this.abstract.startsWith('...') ||
      this.abstract.endsWith('...') ||
      this.maybeDuplicate
    )
      return StudyState.QUARANTINED
    return StudyState.UNSCREENED
  }

  markAsDuplicate(parentStudyId: number) {
    this.titleAndAbstractScreening = ''
    this.fullTextScreening = ''
    this.parentStudyId = parentStudyId
    this.maybeParentStudyId = undefined
  }

  markAsNotDuplicate() {
    this.parentStudyId = undefined
    this.maybeParentStudyId = undefined
  }

  setTitleAndAbstractScreening(key: string) {
    this.titleAndAbstractScreening = key
  }

  clearTitleAndAbstractScreening() {
    this.titleAndAbstractScreening = ''
    this.fullTextScreening = ''
  }

  setFullStudyScreening(key: string) {
    if (!this.titleAndAbstractScreening)
      throw new Error('title and abstract screening missing')
    this.fullTextScreening = key
  }

  clearFullStudyScreening() {
    this.fullTextScreening = ''
  }

  // DO NOT REMOVE THIS PROPERTY
  // @ts-expect-error Property to enforce instance
  private _enforce_instance = ''
}
