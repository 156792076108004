import {
  AlignmentType,
  Bookmark,
  BorderStyle,
  Document,
  ExternalHyperlink,
  FootnoteReferenceRun,
  Header,
  HeadingLevel,
  ImageRun,
  InternalHyperlink,
  ISectionOptions,
  IShadingAttributesProperties,
  ITableWidthProperties,
  LevelFormat,
  Packer,
  PageNumber,
  PageOrientation,
  Paragraph,
  ParagraphChild,
  SectionType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx'
import { Study } from '@core/domain/models/study.model'
import { StudyState } from '@app/types'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { Review } from '@core/domain/models/review.model'
import { ImportSource } from '@core/domain/models/import-source.model'
import { AppraisalCriterion } from '@core/domain/models/appraisal-criterion.model'
import { generateReviewSearchsAndLimtsResults } from './generate-search-terms-limits-result'
import {
  generateReviewScreeningResults,
  SearchScreeningResult,
} from './generate-review-screening-result'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { InclusionCriteria } from '@core/domain/models/inclusion-criteria.model'
import { generateCitation } from './generate-citations'
import { Id } from '@core/domain/types/id.type'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'

let tables: string[] = []
let figures: string[] = []
let tableIndex = () => 0

export async function generateProtocol(
  review: Review,
  appraisalCriteria: AppraisalCriterion[],
  attributesStructure: AttributeStructure[],
  prismaDiagramImage: string,
  inclusionCriterion: InclusionCriteria,
): Promise<Blob> {
  const createTableIndexing = () => {
    let tableNumber = 0
    return () => {
      tableNumber++
      return tableNumber
    }
  }
  tables = []
  figures = []
  tableIndex = createTableIndexing()

  const searches = [...(review?.searches ?? [])]

  if (!review) throw new Error('review not set')

  const studies: Study[] = []
  searches.forEach((c) => {
    c.studies.forEach((a) => studies.push(a))
  })

  const includedStudies: Study[] = studies.filter(
    (s) => s.state === StudyState.INCLUDED,
  )

  const citations = await generateCitation(
    review.studies,
    review.project!.cslStyle!,
  )

  const referencesSection = await buildReferencesSection(review, citations)

  const doc = new Document({
    numbering: {
      config: [
        {
          reference: 'headings',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              alignment: AlignmentType.LEFT,
              text: '%1',
            },
            {
              level: 1,
              format: LevelFormat.DECIMAL,
              alignment: AlignmentType.LEFT,
              text: '%1.%2',
            },
            {
              level: 2,
              format: LevelFormat.DECIMAL,
              alignment: AlignmentType.LEFT,
              text: '%1.%2.%3',
            },
          ],
        },
      ],
    },
    footnotes: {
      1: {
        children: [
          new Paragraph(
            'IMDRF MDCE WG/N56FINAL:2019 (formerly GHTF/SG5/N2R8:2007)',
          ),
        ],
      },
      2: {
        children: [
          new Paragraph(
            'CLINICAL EVALUATION: A GUIDE FOR MANUFACTURERS AND NOTIFIED BODIES UNDER DIRECTIVES 93/42/EEC and 90/385/EEC',
          ),
        ],
      },
      3: {
        children: [
          new Paragraph(
            'OCEBM Levels of Evidence Working Group. “The Oxford Levels of Evidence 2”',
          ),
        ],
      },
    },
    styles: {
      paragraphStyles: [
        {
          id: 'Caption',
          name: 'Caption',
          basedOn: 'Caption',
          quickFormat: true,
          paragraph: {
            spacing: {
              before: 120,
            },
          },
          run: { size: 18, bold: true },
        },
        {
          id: 'TableFooter',
          name: 'TableFooter',
          basedOn: 'Normal',
          quickFormat: true,
          run: { size: 18, bold: true },
        },
        {
          id: 'tableCell',
          name: 'tableCell',
          quickFormat: true,
          paragraph: {
            spacing: {
              before: 0,
            },
          },
          run: { size: 20 },
        },
      ],
      default: {
        title: {
          run: {
            size: 28,
            bold: true,
            color: '7F7F7F',
          },
        },
        heading1: {
          paragraph: {
            spacing: {
              before: 240,
            },
            numbering: { reference: 'headings', level: 0 },
          },
          run: {
            size: 28,
            bold: true,
            color: '000000',
          },
        },
        heading2: {
          paragraph: {
            spacing: {
              before: 120,
            },
            numbering: { reference: 'headings', level: 1 },
          },
          run: {
            size: 24,
            bold: true,
            color: '000000',
          },
        },
        heading3: {
          paragraph: {
            spacing: {
              before: 120,
            },
            numbering: { reference: 'headings', level: 2 },
          },
          run: {
            size: 22,
            bold: true,
            color: '000000',
          },
        },
        heading4: {
          paragraph: {
            spacing: {
              before: 80,
            },
          },
          run: {
            size: 22,
            bold: true,
            color: '000000',
          },
        },

        document: {
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: {
              before: 80,
            },
          },
          run: {
            size: 22,
            font: 'Calibri',
          },
        },
      },
    },
    sections: [
      buildHeaderSection(review),
      buildTitleSection(review),
      buildApprovalSection(review),
      buildScopeOfTheSystematicReviewSection(attributesStructure),
      buildMethodologySection(review, inclusionCriterion),
      buildSearchTermsAndLimitsSection(review, false, citations),
      buildAppraisalStrategySection(review, appraisalCriteria),
      ...(review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
        ? [
            buildOxfordLevelOfEvidenceSection(
              review.plan.appraisalPlan.isImdrfMdce2019Applicable,
            ),
          ]
        : []),
      buildPeerAppraisalSection(review),

      {
        properties: {
          type: SectionType.CONTINUOUS,
          page: {
            size: {
              orientation: PageOrientation.LANDSCAPE,
            },
          },
        },
        children: [
          new Paragraph({
            text: 'Search results',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: 'PRISMA 2020 diagram',
            heading: HeadingLevel.HEADING_2,
          }),
          new Paragraph(
            'The PRISMA 2020 diagram on the following page summarizes the screening results.',
          ),
        ],
      },
      buildPrismaSection(prismaDiagramImage),
      buildSearchTermsAndLimitsSection(review, true, citations),

      buildScreenigResultSection(review, citations),
      ...(review.plan?.appraisalPlan.isImdrfMdce2019Applicable ||
      review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable ||
      review.plan?.appraisalPlan.isPeerReviewStatusApplicable
        ? [_buildAppraisalResultSection(review, citations)]
        : []),
      buildAttributeExtractionSection(
        review,
        attributesStructure,
        includedStudies,
        citations,
      ),

      referencesSection,
      buildTablesReferencesSection(),
      buildFiguresReferencesSection(),
    ],
  })

  return Packer.toBlob(doc)
}

function buildHeaderSection(review: Review): ISectionOptions {
  return {
    headers: {
      default: new Header({
        children: [
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              insideVertical: { style: BorderStyle.NONE },
              top: { style: BorderStyle.NONE },
              bottom: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
              left: { style: BorderStyle.NONE },
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: { size: 83, type: WidthType.PERCENTAGE },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text:
                              (review.project?.name ?? '-') +
                              ' - ' +
                              (review?.name ?? '-'),
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: { size: 17, type: WidthType.PERCENTAGE },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Page ',
                          }),
                          new TextRun({
                            children: [PageNumber.CURRENT],
                            bold: true,
                          }),
                          new TextRun({
                            text: ' Of ',
                          }),
                          new TextRun({
                            children: [PageNumber.TOTAL_PAGES],
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    },
    children: [],
    properties: {
      type: SectionType.CONTINUOUS,
    },
  }
}

function buildTitleSection(review: Review): ISectionOptions {
  return {
    children: [
      new Paragraph({
        text: (review?.project?.name ?? '') + ' - ' + (review?.name ?? ''),
        heading: HeadingLevel.TITLE,
      }),
    ],
    properties: {
      type: SectionType.CONTINUOUS,
    },
  }
}

function buildApprovalSection(review: Review): ISectionOptions {
  const authors = review.authors

  const reviewers = review.reviewers

  const approvers = review.approvers

  const externalApprovers = review.externalApprovers

  return {
    children: [
      new Paragraph({
        text: 'Approval',
        heading: HeadingLevel.HEADING_4,
      }),
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              buildTableHeaderCell({
                text: 'Role',
              }),
              buildTableHeaderCell({
                text: 'Name',
              }),
              buildTableHeaderCell({
                text: 'Date',
              }),
              buildTableHeaderCell({
                text: 'Signature',
              }),
            ],
          }),
          ...(authors.length > 0
            ? authors.map(
                (a) =>
                  new TableRow({
                    children: [
                      buildTableHeaderCell({
                        text: 'Author',
                      }),
                      buildTableCell({
                        children: [
                          new TextRun(
                            (a?.firstName ?? '-') + ' ' + (a?.lastName ?? '-'),
                          ),
                        ],
                      }),
                      buildTableCell({
                        children: [],
                      }),
                      buildTableCell({
                        children: [],
                      }),
                    ],
                  }),
              )
            : [
                new TableRow({
                  children: [
                    buildTableHeaderCell({
                      text: 'Author',
                    }),
                    buildTableCell({
                      children: [],
                    }),
                    buildTableCell({
                      children: [],
                    }),
                    buildTableCell({
                      children: [],
                    }),
                  ],
                }),
              ]),

          ...(reviewers.length > 0
            ? reviewers.map(
                (a) =>
                  new TableRow({
                    children: [
                      buildTableHeaderCell({
                        text: 'Reviewer',
                      }),
                      buildTableCell({
                        children: [
                          new TextRun(
                            (a?.firstName ?? '-') + ' ' + (a?.lastName ?? '-'),
                          ),
                        ],
                      }),
                      buildTableCell({
                        children: [],
                      }),
                      buildTableCell({
                        children: [],
                      }),
                    ],
                  }),
              )
            : [
                new TableRow({
                  children: [
                    buildTableHeaderCell({
                      text: 'Reviewer',
                    }),
                    buildTableCell({
                      children: [],
                    }),
                    buildTableCell({
                      children: [],
                    }),
                    buildTableCell({
                      children: [],
                    }),
                  ],
                }),
              ]),

          ...(approvers?.map(
            (a) =>
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: 'Approver',
                  }),
                  buildTableCell({
                    children: [
                      new TextRun(
                        (a?.firstName ?? '-') + ' ' + (a?.lastName ?? '-'),
                      ),
                    ],
                  }),
                  buildTableCell({
                    children: [],
                  }),
                  buildTableCell({
                    children: [],
                  }),
                ],
              }),
          ) ?? []),

          ...(externalApprovers?.map(
            (a) =>
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: 'Approver',
                  }),
                  buildTableCell({
                    children: [new TextRun(a?.name ?? '-')],
                  }),
                  buildTableCell({
                    children: [],
                  }),
                  buildTableCell({
                    children: [],
                  }),
                ],
              }),
          ) ?? []),
          ...(approvers.length <= 0 && externalApprovers.length <= 0
            ? [
                new TableRow({
                  children: [
                    buildTableHeaderCell({
                      text: 'Approver',
                    }),
                    buildTableCell({
                      children: [],
                    }),
                    buildTableCell({
                      children: [],
                    }),
                    buildTableCell({
                      children: [],
                    }),
                  ],
                }),
              ]
            : []),
        ],
      }),
    ],
    properties: {
      type: SectionType.CONTINUOUS,
    },
  }
}

function buildOxfordLevelOfEvidenceSection(
  isImdrfMdce2019Applicable: boolean,
): ISectionOptions {
  const result: (Paragraph | Table)[] = []
  const OxfordLevelOfEvidenceTableIndex = tableIndex()
  if (!isImdrfMdce2019Applicable)
    result.push(
      new Paragraph({
        text: 'Appraisal strategy',
        heading: HeadingLevel.HEADING_2,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: 'Included articles for the SOTA medical field are guidelines and practice guidelines. The data appraisal will be performed according to the Oxford level of evidence',
          }),
          new FootnoteReferenceRun(3),
          new TextRun({
            text: `in table ${OxfordLevelOfEvidenceTableIndex}.`,
          }),
        ],
      }),
    )
  result.push(
    new Paragraph({
      text: 'Oxford level of evidence',
      heading: HeadingLevel.HEADING_4,
    }),
  )
  tables.push(
    `Table ${OxfordLevelOfEvidenceTableIndex}: Study levels of evidence criteria.`,
  )
  result.push(
    new Paragraph({
      style: 'Caption',
      children: [
        new Bookmark({
          id: `Table ${OxfordLevelOfEvidenceTableIndex}: Study levels of evidence criteria.`,
          children: [
            new TextRun(
              `Table ${OxfordLevelOfEvidenceTableIndex}: Study levels of evidence criteria.`,
            ),
          ],
        }),
      ],
    }),
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'Question',
              width: { size: 20, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 1 (Level 1*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 2 (Level 2*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 3 (Level 3*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 4 (Level 4*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 5 (Level 5*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'How common is the problem?',
              fillColor: HeaderCellFillColor.QUESTION,
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Local and current random sample surveys (or censuses)',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of surveys that allow matching to local circumstances**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Local non-random sample**')],
            }),
            buildTableCell({
              children: [new TextRun('Case-series**')],
            }),
            buildTableCell({
              children: [new TextRun('n/a')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'Is this diagnostic or monitoring test accurate? (Diagnosis)',
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of cross sectional studies with consistently applied reference standard and blinding',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Individual cross sectional studies with consistently applied reference standard and blinding',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Non-consecutive studies, or studies without consistently applied reference standards**',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-control studies, or “poor or non-independent reference standard**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Mechanism-basedreasoning')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'What will happen if we do not add a therapy? (Prognosis)',
            }),
            buildTableCell({
              children: [
                new TextRun('Systematic review of inception cohort studies'),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Inception cohort studies')],
            }),
            buildTableCell({
              children: [
                new TextRun('Cohort study or control arm of randomized trial*'),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-series or casecontrol studies, or poor quality prognostic cohort study**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('n/a')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'Does this intervention help? (Treatment Benefits)',
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of randomized trials or n-of-1 trials',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Randomized trial or observational study with dramatic effect',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Non-randomized controlled cohort/follow-up study**',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-series, case-control studies, or historically controlled studies**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Mechanism-basedreasoning')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'What are the COMMON harms? (Treatment Harms)',
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of randomized trials, systematic review of nested case-control studies, nof-1 trial with the patient you are raising the question about, or observational study with dramatic effect',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Individual randomized trial or (exceptionally) observational study with dramatic effect',
                ),
              ],
            }),
            buildTableCell({
              rowSpan: 2,
              children: [
                new TextRun(
                  'Non-randomized controlled cohort/follow-up study (post-marketing surveillance) provided there are sufficient numbers to rule out a common harm. (For long-term harms the duration of follow-up must be sufficient.)**',
                ),
              ],
            }),
            buildTableCell({
              rowSpan: 2,
              children: [
                new TextRun('Case-series, case-control, or historically'),
              ],
            }),
            buildTableCell({
              rowSpan: 2,
              children: [new TextRun('Mechanism-basedreasoning')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'What are the RARE harms? (Treatment Harms',
              fillColor: HeaderCellFillColor.QUESTION,
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of randomized trials or n-of-1 trial',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Randomized trial or (exceptionally) observational study with dramatic effect',
                ),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'Is this (early detection) test worthwhile? (Screening)',
              fillColor: HeaderCellFillColor.QUESTION,
            }),
            buildTableCell({
              children: [new TextRun('Systematic review of randomized trials')],
            }),
            buildTableCell({
              children: [new TextRun('Randomized trial')],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Non -randomized controlled cohort/follow-up study**',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-series, case-control, or historically controlled studies**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Mechanism-basedreasoning')],
            }),
          ],
        }),
      ],
    }),
    new Paragraph({
      text: '* Level may be graded down on the basis of study quality, imprecision, indirectness (study PICO does not match questions PICO), because of inconsistency between studies, or because the absolute effect size is very small; Level may be graded up if there is a large or very large effect size.',
      style: 'TableFooter',
    }),
    new Paragraph({
      text: '** As always, a systematic review is generally better than an individual study.',
      style: 'TableFooter',
    }),
  )
  return {
    children: result,
    properties: {
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
  }
}

function buildMethodologySection(
  review: Review,
  inclusionCriterion: InclusionCriteria,
): ISectionOptions {
  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children: [
      ...[
        new Paragraph({
          text: 'Search methodology',
          heading: HeadingLevel.HEADING_1,
        }),
      ],
      ...[
        new Paragraph({
          text: 'Inclusion criteria - PICO(TS) strategy',
          heading: HeadingLevel.HEADING_2,
        }),

        ...(() => {
          const inclusionCriteriaTableIndex = tableIndex()
          tables.push(
            `Table ${inclusionCriteriaTableIndex}: Inclusion criteria`,
          )
          return [
            new Paragraph(
              `Table ${inclusionCriteriaTableIndex} summarizes the inclusion criteria of the systematic literature search.`,
            ),
            new Paragraph({
              children: [
                new Bookmark({
                  id: `Table ${inclusionCriteriaTableIndex}: Inclusion criteria`,
                  children: [
                    new TextRun(
                      `Table ${inclusionCriteriaTableIndex}: Inclusion criteria`,
                    ),
                  ],
                }),
              ],
              style: 'Caption',
            }),
          ]
        })(),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            ...[
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: 'Patient, problem or population',
                    columnSpan: 1,
                  }),
                  buildTableCell({
                    columnSpan: 2,
                    children: [
                      ...(inclusionCriterion?.patient?.map(
                        (c: string, i: number) =>
                          new TextRun({
                            text: c,
                            break: i > 0 ? 1 : 0,
                          }),
                      ) ?? []),
                    ],
                  }),
                ],
              }),
            ],
            ...[
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: 'Intervention',
                    columnSpan: 1,
                  }),
                  buildTableCell({
                    columnSpan: 2,
                    children: [
                      ...(inclusionCriterion?.intervention?.map(
                        (c: string, i: number) =>
                          new TextRun({
                            text: c,
                            break: i > 0 ? 1 : 0,
                          }),
                      ) ?? []),
                    ],
                  }),
                ],
              }),
            ],
            ...[
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: 'Comparison, control, or comparator',
                    columnSpan: 1,
                  }),
                  buildTableCell({
                    columnSpan: 2,
                    children: [
                      ...(inclusionCriterion?.comparisonControlComparator?.map(
                        (c: string, i: number) =>
                          new TextRun({
                            text: c,
                            break: i > 0 ? 1 : 0,
                          }),
                      ) ?? []),
                    ],
                  }),
                ],
              }),
            ],
            ...[
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    columnSpan: 1,
                    text: 'Outcomes',
                    width: { size: 35, type: WidthType.PERCENTAGE },
                  }),
                  buildTableCell({
                    columnSpan: 2,
                    children: [
                      ...(inclusionCriterion?.outcomes?.map(
                        (c: string, i: number) =>
                          new TextRun({
                            text: c,
                            break: i > 0 ? 1 : 0,
                          }),
                      ) ?? []),
                    ],
                  }),
                ],
              }),
            ],
            ...[
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: 'Study design',
                    columnSpan: 1,
                  }),
                  buildTableCell({
                    columnSpan: 2,
                    children: [
                      ...(inclusionCriterion?.studyDesign?.map(
                        (c: string, i: number) =>
                          new TextRun({
                            text: c,
                            break: i > 0 ? 1 : 0,
                          }),
                      ) ?? []),
                    ],
                  }),
                ],
              }),
            ],
            ...[
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: 'Timing',
                    columnSpan: 1,
                  }),
                  buildTableCell({
                    columnSpan: 2,
                    children: [
                      ...(inclusionCriterion?.timing?.map(
                        (c: string, i: number) =>
                          new TextRun({
                            text: c,
                            break: i > 0 ? 1 : 0,
                          }),
                      ) ?? []),
                    ],
                  }),
                ],
              }),
            ],
          ],
        }),
      ],

      ...[
        new Paragraph({
          text: 'Exclusion criteria',
          heading: HeadingLevel.HEADING_2,
        }),

        ..._buildExclusionCriteriaSection(review),
      ],

      new Paragraph({
        text: 'Databases',
        heading: HeadingLevel.HEADING_2,
      }),

      ..._buildImportSourcesSection(review),
    ],
  }
}

function buildScopeOfTheSystematicReviewSection(
  scopeOfSystematicReview: AttributeStructure[],
): ISectionOptions {
  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },

    children: [
      ...[
        new Paragraph({
          text: 'Scope of the systematic review',
          heading: HeadingLevel.HEADING_1,
        }),
      ],
      ...(scopeOfSystematicReview.length > 0
        ? (() => {
            const scopeTableIndex = tableIndex()
            tables.push(
              `Table ${scopeTableIndex}: scope of the systematic review`,
            )

            const tableContent = [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Table ${scopeTableIndex}: summarizes the scope of the systematic review.`,
                  }),
                ],
              }),
              new Paragraph({
                style: 'Caption',
                children: [
                  new Bookmark({
                    id: `Table ${scopeTableIndex}: scope of the systematic review`,
                    children: [
                      new TextRun(
                        `Table ${scopeTableIndex}: scope of the systematic review`,
                      ),
                    ],
                  }),
                ],
              }),
              new Table({
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                rows: [
                  new TableRow({
                    children: [
                      buildTableHeaderCell({
                        text: 'Scope',
                      }),
                      buildTableHeaderCell({
                        text: 'Question',
                      }),
                    ],
                  }),
                  ...scopeOfSystematicReview.map(
                    (s) =>
                      new TableRow({
                        children: [
                          buildTableCell({
                            children: [
                              new TextRun({
                                bold: true,
                                text: s.label ?? '-',
                              }),
                            ],
                          }),
                          buildTableCell({
                            children: [
                              new TextRun({
                                text: s.question ?? '-',
                              }),
                            ],
                          }),
                        ],
                      }),
                  ),
                ],
              }),
            ]

            return tableContent
          })()
        : [
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Enter scope',
                  highlight: 'yellow',
                }),
              ],
            }),
          ]),
    ],
  }
}

function buildPrismaSection(image: string): ISectionOptions {
  figures.push('Prisma diagram')
  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
        margin: {
          bottom: 200,
          left: 200,
          right: 200,
          top: 200,
        },
      },
    },
    children: [
      new Paragraph({
        children: [
          new Bookmark({
            id: 'Prisma diagram',
            children: [],
          }),
          new ImageRun({
            data: image,
            altText: {
              description: 'Prisma diagram',
              name: 'Prisma diagram',
              title: 'Prisma diagram',
            },
            transformation: {
              width: 1085,
              height: 700,
            },
          }),
        ],
      }),
    ],
  }
}

function _buildAppraisalSection(appraisalCriteria: AppraisalCriterion[]) {
  const rows: TableRow[] = appraisalCriteria
    .map((criterion) => {
      return (
        criterion.answers?.map((answer, index) => {
          return new TableRow({
            children: [
              ...(index === 0
                ? [
                    buildTableCell({
                      children: [new TextRun(criterion.title)],
                      rowSpan: criterion.answers?.length,
                    }),
                  ]
                : []),
              ...(index === 0
                ? [
                    buildTableCell({
                      children: [new TextRun(criterion.question)],
                      rowSpan: criterion.answers?.length,
                    }),
                  ]
                : []),
              buildTableCell({
                children: [new TextRun(criterion.id + (index + 1))],
                width: {
                  size: 400,
                  type: WidthType.DXA,
                },
              }),
              buildTableCell({
                children: [new TextRun(answer.title)],
              }),
            ],
          })
        }) ?? []
      )
    })
    .flat()
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          buildTableHeaderCell({
            text: 'Criterion',
            width: {
              size: 2500,
              type: WidthType.DXA,
            },
          }),
          buildTableHeaderCell({
            text: 'Description',
          }),
          buildTableHeaderCell({
            text: 'Grading system',
            columnSpan: 2,

            width: {
              size: 2700,
              type: WidthType.DXA,
            },
          }),
        ],
      }),
      ...rows,
    ],
  })
}

function _buildAppraisalResultSection(
  review: Review,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
): ISectionOptions {
  const children: (Paragraph | Table)[] = [
    new Paragraph({
      text: 'Appraisal results',
      heading: HeadingLevel.HEADING_1,
    }),
  ]

  if (review.plan?.appraisalPlan.isImdrfMdce2019Applicable) {
    children.push(
      ..._buildIMDFAppraisalResultSection(review, citations).children,
    )
  }

  if (review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable) {
    children.push(
      ..._buildOxfordLevelOfEvidenceAppraisalResultSection(review, citations)
        .children,
    )
  }

  if (review.plan?.appraisalPlan.isPeerReviewStatusApplicable) {
    children.push(
      ..._buildPeerReviewAppraisalResultSection(review, citations).children,
    )
  }

  return { children }
}

function _buildIMDFAppraisalResultSection(
  review: Review,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
) {
  const appraisalResultTableIndex = tableIndex()
  tables.push(
    `Table ${appraisalResultTableIndex}: the IMDRF MDCE 2019 appraisal of the included articles`,
  )
  const findAppraisalAnswerIndex = (
    appraisalCriterionId: string,
    studyAppraisalAnswerId: string,
  ) => {
    let answerIndexText = '-'
    const criterion = review?.plan?.appraisalCriteria?.find(
      (s) => s.id === appraisalCriterionId,
    )
    if (criterion) {
      const answerIndex = criterion.answers?.findIndex(
        (a) => a.id === studyAppraisalAnswerId,
      )
      if (answerIndex !== -1) {
        answerIndexText = appraisalCriterionId + (answerIndex + 1).toString()
      }
    }

    return answerIndexText
  }

  const buildAppraisalResultRow = (study: Study) => {
    return new TableRow({
      children: [
        buildTableCell({
          children: [
            new TextRun({
              text: citations.findByStudyId(study.id).inText,
            }),
          ],
        }),
        ...['D', 'A', 'P', 'R', 'T'].map((t) => {
          return buildTableCell({
            children: [
              new TextRun({
                text: study.appraisal?.[t]
                  ? findAppraisalAnswerIndex(t, study.appraisal?.[t])
                  : '-',
              }),
            ],
          })
        }),
        ...['O', 'F', 'S', 'C'].map((t) => {
          return buildTableCell({
            children: [
              new TextRun({
                text: study.appraisal?.[t]
                  ? findAppraisalAnswerIndex(t, study.appraisal?.[t])
                  : '-',
              }),
            ],
          })
        }),
      ],
    })
  }

  const buildAppraisalTableHeaderRow = () => {
    return new TableRow({
      children: [
        buildTableHeaderCell({
          text: 'Study ID',
          width: { size: 2500, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'Data Suitability',
          columnSpan: 5,
          width: { size: 1800, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'Data Contribution',
          columnSpan: 4,
          width: { size: 1800, type: WidthType.DXA },
        }),
      ],
    })
  }

  const appraisalResultRows = review.studies
    .map((s) => {
      return s.state === StudyState.INCLUDED ? buildAppraisalResultRow(s) : []
    })
    .flat()
  return {
    children: [
      ...[
        new Paragraph({
          text: 'IMDRF',
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Table ${appraisalResultTableIndex} summarizes the IMDRF appraisal of the included articles.`,
            }),
          ],
        }),
        new Paragraph({
          style: 'Caption',
          children: [
            new Bookmark({
              id: `Table ${appraisalResultTableIndex}: the IMDRF appraisal of the included articles`,
              children: [
                new TextRun(
                  `Table ${appraisalResultTableIndex}: the IMDRF appraisal of the included articles`,
                ),
              ],
            }),
          ],
        }),
      ],

      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [buildAppraisalTableHeaderRow(), ...appraisalResultRows],
      }),
    ],
  }
}

function _buildOxfordLevelOfEvidenceAppraisalResultSection(
  review: Review,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
) {
  const appraisalResultTableIndex = tableIndex()
  tables.push(
    `Table ${appraisalResultTableIndex}: the oxford level of evidence appraisal of the included articles`,
  )

  const buildAppraisalResultRow = (study: Study) => {
    return new TableRow({
      children: [
        buildTableCell({
          children: [
            new TextRun({
              text: citations.findByStudyId(study.id).inText,
            }),
          ],
        }),
        buildTableCell({
          children: [
            new TextRun({
              text: study.oxfordLevelOfEvidence
                ? study.oxfordLevelOfEvidence
                : '-',
            }),
          ],
        }),
      ],
    })
  }

  const buildAppraisalTableHeaderRow = () => {
    return new TableRow({
      children: [
        buildTableHeaderCell({
          text: 'Study ID',
          width: { size: 2500, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'Oxford level of evidence',
          width: { size: 1300, type: WidthType.DXA },
        }),
      ],
    })
  }

  const appraisalResultRows = review.studies
    .map((s) => {
      return s.state === StudyState.INCLUDED ? buildAppraisalResultRow(s) : []
    })
    .flat()
  return {
    children: [
      ...[
        new Paragraph({
          text: 'Oxford level of evidence appraisal results',
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Table ${appraisalResultTableIndex} summarizes the oxford level of evidence appraisal of the included articles.`,
            }),
          ],
        }),
        new Paragraph({
          style: 'Caption',
          children: [
            new Bookmark({
              id: `Table ${appraisalResultTableIndex} summarizes the oxford level of evidence appraisal of the included articles`,
              children: [
                new TextRun(
                  `Table ${appraisalResultTableIndex} summarizes the oxford level of evidence appraisal of the included articles`,
                ),
              ],
            }),
          ],
        }),
      ],

      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [buildAppraisalTableHeaderRow(), ...appraisalResultRows],
      }),
    ],
  }
}

function _buildPeerReviewAppraisalResultSection(
  review: Review,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
) {
  const peerReviewStatusMap = {
    [PeerReviewStatus.yes]: {
      text: 'Yes',
    },
    [PeerReviewStatus.no]: {
      text: 'No',
    },
    [PeerReviewStatus.notApplicable]: {
      text: 'Not applicable',
    },
  }
  const appraisalResultTableIndex = tableIndex()
  tables.push(
    `Table ${appraisalResultTableIndex}: the peer review status appraisal of the included articles`,
  )

  const buildAppraisalResultRow = (study: Study) => {
    return new TableRow({
      children: [
        buildTableCell({
          children: [
            new TextRun({
              text: citations.findByStudyId(study.id).inText,
            }),
          ],
        }),
        buildTableCell({
          children: [
            new TextRun({
              text: study.peerReviewStatus
                ? peerReviewStatusMap[study.peerReviewStatus].text
                : '-',
            }),
          ],
        }),
      ],
    })
  }

  const buildAppraisalTableHeaderRow = () => {
    return new TableRow({
      children: [
        buildTableHeaderCell({
          text: 'Study ID',
          width: { size: 2500, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'peer review status',
          width: { size: 1300, type: WidthType.DXA },
        }),
      ],
    })
  }

  const appraisalResultRows = review.studies
    .map((s) => {
      return s.state === StudyState.INCLUDED ? buildAppraisalResultRow(s) : []
    })
    .flat()
  return {
    children: [
      ...[
        new Paragraph({
          text: 'Peer review status',
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Table ${appraisalResultTableIndex} summarizes the peer review status appraisal of the included articles.`,
            }),
          ],
        }),
        new Paragraph({
          style: 'Caption',
          children: [
            new Bookmark({
              id: `Table ${appraisalResultTableIndex} summarizes the peer review status appraisal of the included articles`,
              children: [
                new TextRun(
                  `Table ${appraisalResultTableIndex} summarizes the peer review  status appraisal of the included articles`,
                ),
              ],
            }),
          ],
        }),
      ],

      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [buildAppraisalTableHeaderRow(), ...appraisalResultRows],
      }),
    ],
  }
}

function buildAppraisalStrategySection(
  review: Review,
  appraisalCriteria: AppraisalCriterion[],
): ISectionOptions {
  const result: (Paragraph | Table)[] = []
  if (review.plan?.appraisalPlan.isImdrfMdce2019Applicable) {
    const suitabilityCriteriaTableIndex = tableIndex()
    const dataContributionCriteriaTableIndex = tableIndex()
    tables.push(
      `Table ${suitabilityCriteriaTableIndex}: Criteria for the appraisal of suitability`,
      `Table ${dataContributionCriteriaTableIndex}: Criteria for the appraisal of the data contribution`,
    )
    result.push(
      new Paragraph({
        text: 'Appraisal strategy',
        heading: HeadingLevel.HEADING_2,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: `The included records will be appraised according to IMDRF MDCE 2019`,
          }),
          new FootnoteReferenceRun(1),

          new TextRun({
            text: `and MEDDEV 2.7.1 Rev. 4.`,
          }),
          new FootnoteReferenceRun(2),
          new TextRun({
            text: ` Table ${suitabilityCriteriaTableIndex} and Table ${dataContributionCriteriaTableIndex} detail the suitability and data contribution criteria.`,
          }),

          ...(review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
            ? [
                new TextRun({
                  text: ` In addition, the data will be appraised according to the Oxford level of evidence,`,
                }),
                new FootnoteReferenceRun(3),
                new TextRun({
                  text: ` Table ${dataContributionCriteriaTableIndex + 1}.`,
                }),
              ]
            : []),
        ],
      }),
      new Paragraph({
        text: 'Appraisal of suitability',
        heading: HeadingLevel.HEADING_4,
      }),

      new Paragraph({
        style: 'Caption',
        children: [
          new Bookmark({
            id: `Table ${suitabilityCriteriaTableIndex}: Criteria for the appraisal of suitability`,
            children: [
              new TextRun(
                `Table ${suitabilityCriteriaTableIndex}: Criteria for the appraisal of suitability`,
              ),
            ],
          }),
        ],
      }),
      _buildAppraisalSection(
        appraisalCriteria.filter((c) =>
          ['D', 'A', 'P', 'R', 'T'].includes(c.id),
        ),
      ),
      new Paragraph({
        text: 'Appraisal of the data contribution',
        heading: HeadingLevel.HEADING_4,
      }),
      new Paragraph({
        style: 'Caption',
        children: [
          new Bookmark({
            id: `Table ${dataContributionCriteriaTableIndex}: Criteria for the appraisal of the data contribution`,
            children: [
              new TextRun(
                `Table ${dataContributionCriteriaTableIndex}: Criteria for the appraisal of the data contribution`,
              ),
            ],
          }),
        ],
      }),
      _buildAppraisalSection(
        appraisalCriteria.filter((c) => ['O', 'F', 'S', 'C'].includes(c.id)),
      ),
    )
  }
  return {
    properties: {
      type: SectionType.CONTINUOUS,
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: result,
  }
}

function buildPeerAppraisalSection(review: Review): ISectionOptions {
  const result: (Paragraph | Table)[] = []
  if (review.plan?.appraisalPlan.isPeerReviewStatusApplicable) {
    const peerAppraisalTableIndex = tableIndex()
    tables.push(
      `Table ${peerAppraisalTableIndex}: Criteria for the peer review status appraisal`,
    )
    result.push(
      new Paragraph({
        text: 'Appraisal of the peer review status',
        heading: HeadingLevel.HEADING_4,
      }),
      new Paragraph({
        style: 'Caption',
        children: [
          new Bookmark({
            id: `Table ${peerAppraisalTableIndex}: Criteria for the peer review status appraisal`,
            children: [
              new TextRun(
                `Table ${peerAppraisalTableIndex}: Criteria for the peer review status appraisal`,
              ),
            ],
          }),
        ],
      }),
    )
    result.push(
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              buildTableHeaderCell({
                text: 'Criterion',
              }),
            ],
          }),
          new TableRow({
            children: [
              buildTableCell({
                children: [new TextRun('Yes')],
              }),
            ],
          }),
          new TableRow({
            children: [
              buildTableCell({
                children: [new TextRun('No')],
              }),
            ],
          }),
          new TableRow({
            children: [
              buildTableCell({
                children: [new TextRun('Not applicable')],
              }),
            ],
          }),
        ],
      }),
    )
  }
  return {
    properties: {
      type: SectionType.CONTINUOUS,
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: result,
  }
}

function _buildCriteriaTable(criteria: string[], tableHeader: string) {
  const buildCriteriaRows = () => {
    return new TableRow({
      children: [
        buildTableCell({
          children: [
            ...(criteria.map(
              (c: string, i: number) =>
                new TextRun({
                  text: c,
                  break: i > 0 ? 1 : 0,
                }),
            ) ?? []),
          ],
        }),
      ],
    })
  }
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          buildTableHeaderCell({
            text: tableHeader,
            width: { size: 100, type: WidthType.PERCENTAGE },
          }),
        ],
      }),

      buildCriteriaRows(),
    ],
  })
}

function _buildImportSourcesTable(importSources: ImportSource[]) {
  const buildImportSourceRow = (source: ImportSource) => {
    const descriptionLines = source.description?.split('\n')

    const descriptionTextRuns =
      descriptionLines?.map(
        (line, index) =>
          new TextRun({
            text: line,
            break: index > 0 ? 1 : 0,
          }),
      ) ?? []

    return new TableRow({
      children: [
        buildTableCell({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: source.name,
                  style: 'Hyperlink',
                }),
              ],
              link: source.url ?? '',
            }),
          ],
        }),
        buildTableCell({
          children: descriptionTextRuns,
        }),
      ],
    })
  }

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          buildTableHeaderCell({
            text: 'Short name',
            width: { size: 20, type: WidthType.PERCENTAGE },
          }),
          buildTableHeaderCell({
            text: 'Description',
            width: { size: 80, type: WidthType.PERCENTAGE },
          }),
        ],
      }),
      ...importSources.map((i) => buildImportSourceRow(i)),
    ],
  })
}

function _buildExclusionCriteriaSection(review: Review) {
  const exclusionCriteriaSection: (Paragraph | Table)[] = []

  if ((review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) > 0) {
    const firstExclusionCriteriaTable = tableIndex()
    const text =
      (review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) > 0
        ? `Table ${firstExclusionCriteriaTable} summarizes the inclusion and exclusion criteria for screening based on title and abstract (record screening).`
        : `Table ${firstExclusionCriteriaTable} summarizes the inclusion and exclusion criteria for screening.`
    const caption =
      (review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) > 0
        ? `Table ${firstExclusionCriteriaTable}: Inclusion and exclusion criteria for screening based on title and abstract (record screening)`
        : `Table ${firstExclusionCriteriaTable}: Inclusion and exclusion criteria for screening`
    tables.push(caption)
    exclusionCriteriaSection.push(
      new Paragraph({
        children: [
          new TextRun({
            text,
          }),
        ],
      }),
      new Paragraph({
        children: [
          new Bookmark({
            id: caption,
            children: [new TextRun(caption)],
          }),
        ],
        style: 'Caption',
      }),

      _buildCriteriaTable(
        review.plan?.screeningPlan.titleAndAbstractCriteria ?? [],
        'Exclusion criteria for record screening',
      ),
    )
  }

  const secondExclusionCriteriaTable = tableIndex()
  tables.push(
    `Table ${secondExclusionCriteriaTable}: Inclusion and exclusion criteria for screening based on full text (report screening)`,
  )
  exclusionCriteriaSection.push(
    new Paragraph({
      children: [
        new TextRun({
          text: `Table ${secondExclusionCriteriaTable} summarizes the inclusion and exclusion criteria for screening based on full text (report screening).`,
        }),
      ],
    }),
    new Paragraph({
      style: 'Caption',
      children: [
        new Bookmark({
          id: `Table ${secondExclusionCriteriaTable}: Inclusion and exclusion criteria for screening based on full text (report screening)`,
          children: [
            new TextRun(
              `Table ${secondExclusionCriteriaTable}: Inclusion and exclusion criteria for screening based on full text (report screening)`,
            ),
          ],
        }),
      ],
    }),
    _buildCriteriaTable(
      review.plan?.screeningPlan.fullTextCriteria ?? [],
      'Exclusion criteria for report screening',
    ),
  )

  exclusionCriteriaSection.push(
    new Paragraph({
      children: [
        new TextRun({
          text:
            (review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) >
            0
              ? 'According to the Prisma 2020 guidelines, only records retrieved from databases and registries will be screened based on title and abstract. All records will be screened based on full text.'
              : 'According to the Prisma 2020 guidelines, all records will be screened based on full text.',
        }),
      ],
    }),
  )

  return exclusionCriteriaSection
}

function _buildImportSourcesSection(review: Review) {
  const importSourcesSection: (Paragraph | Table)[] = []
  const groupedImportSources: {
    type: ImportSourceType
    title: (index: number) => string
    caption: (index: number) => string
    importSources?: ImportSource[]
  }[] = [
    (() => {
      return {
        type: ImportSourceType.DATABASE,
        title: (index: number) =>
          `Table ${index} details the scientific databases and registers that will be used for the systematic literature search.`,
        caption: (index: number) =>
          `Table ${index}: List of scientific databases and registers`,
      }
    })(),

    (() => {
      return {
        type: ImportSourceType.OTHER_SOURCE,
        title: (index: number) =>
          `Table ${index} details the other sources that will be used for the systematic literature search. According to the Prisma 2020 guidelines, websites differ from scientific databases in that the search results cannot be replicated.`,
        caption: (index: number) =>
          `Table ${index}: List of scientific other sources`,
      }
    })(),
  ]

  groupedImportSources.forEach((s) => {
    s.importSources = review.plan?.importPlan
      ?.importSources!.filter((i) => i.type === s.type)
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
    if ((s.importSources?.length ?? 0) > 0) {
      const index = tableIndex()
      importSourcesSection.push(
        new Paragraph({
          text: s.title(index),
        }),
        new Paragraph({
          children: [
            new Bookmark({
              id: s.caption(index),
              children: [new TextRun(s.caption(index))],
            }),
          ],
          style: 'Caption',
        }),
        _buildImportSourcesTable(s.importSources!),
      )
      tables.push(s.caption(index))
    }
  })

  return importSourcesSection
}

function buildSearchTermsAndLimitsSection(
  review: Review,
  includeStats: boolean,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
): ISectionOptions {
  const _buildSearchTermsAndLimitsRows = (
    review: Review,
    includeStats: boolean,
  ) => {
    const searchTermsAndLimitsRows: TableRow[] = []
    const searchesResults = generateReviewSearchsAndLimtsResults(
      review,
      includeStats,
    )
    searchesResults.forEach((s) => {
      let sourceText = ''
      if (s.parentStudyId) {
        const parentReference = citations.findByStudyId(s.parentStudyId).inText
        const parentReferenceText = ` parent reference: ${parentReference}`
        sourceText = `${s.source}${parentReferenceText}`
      } else {
        sourceText = s.source
      }

      searchTermsAndLimitsRows.push(
        new TableRow({
          children: [
            buildTableCell({
              children: [
                new TextRun({
                  text: s.searchNumber.toString(),
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: sourceText,
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: s.searchDate,
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: s.personUndertakingSearch,
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: s.query,
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: s.filters,
                }),
              ],
            }),

            buildTableCell({
              children: [
                new TextRun({
                  text: s.searchDetails,
                }),
              ],
            }),

            ...(includeStats
              ? [
                  s.empty
                    ? buildTableCell({
                        children: [
                          new TextRun({
                            text: 'The search returned no records',
                          }),
                        ],
                      })
                    : buildTableCell({
                        children: [
                          new TextRun({
                            text: `Hits: ${s.statSummary.hits}`,
                            bold: true,
                          }),

                          new TextRun({
                            text: `Duplicates: ${s.statSummary.duplicated}`,
                            bold: true,
                            break: 1,
                          }),
                          new TextRun({
                            text: `Excluded: ${s.statSummary.excluded}`,
                            bold: true,
                            break: 1,
                          }),
                          ...s.statSummary.exclusionSummarytext
                            .split('\n')
                            .map((l) => {
                              return new TextRun({
                                text: l,
                                break: 1,
                              })
                            }),

                          new TextRun({
                            text: `Included: ${s.statSummary.included}`,
                            bold: true,
                          }),
                        ],
                      }),
                ]
              : []),
          ],
        }),
      )
    })
    return searchTermsAndLimitsRows
  }
  const searchTermsTableIndex = tableIndex()
  const tableTitle = includeStats
    ? 'The screening by search.'
    : 'The search terms and limits'
  tables.push(`Table ${searchTermsTableIndex}: ${tableTitle}`)
  return {
    properties: {
      type: SectionType.NEXT_PAGE,
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: [
      ...[
        new Paragraph({
          text: includeStats
            ? 'Screening results by search'
            : 'Search terms and limits',
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Table ${searchTermsTableIndex} ${
                includeStats
                  ? 'summarizes the screening by search.'
                  : 'summarizes the search terms and limits.'
              } `,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new Bookmark({
              id: `Table ${searchTermsTableIndex}: ${tableTitle}`,
              children: [
                new TextRun(`Table ${searchTermsTableIndex}: ${tableTitle}`),
              ],
            }),
          ],
          style: 'Caption',
        }),
      ],
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            tableHeader: true,
            children: [
              buildTableHeaderCell({
                text: '#',
                width: { size: 2, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Source',
                width: { size: 8, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Search Date',
                width: { size: 8, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Person undertaking the search',
                width: { size: 12, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Query',

                width: { size: 10, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Filters',
                width: { size: 10, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Search Details',
                width: { size: 22, type: WidthType.PERCENTAGE },
              }),

              ...(includeStats
                ? [
                    buildTableHeaderCell({
                      text: 'Screening summary',
                      width: { size: 28, type: WidthType.PERCENTAGE },
                    }),
                  ]
                : []),
            ],
          }),
          ..._buildSearchTermsAndLimitsRows(review, includeStats),
        ],
      }),
    ],
  }
}

function buildScreenigResultSection(
  review: Review,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
): ISectionOptions {
  function buildSearchScreeningResultBreakdownSection(
    search: SearchScreeningResult,
  ): (Paragraph | Table)[] {
    const cslStyle = review.project?.cslStyle
      ? review.project.cslStyle.charAt(0).toUpperCase() +
        review.project.cslStyle.replaceAll('-', ' ').slice(1)
      : '-'
    return [
      new Paragraph({
        text: `Search ${search.searchNumber}`,
        heading: HeadingLevel.HEADING_3,
      }),
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            tableHeader: true,
            children: [
              buildTableHeaderCell({
                text: `Reference of ${cslStyle ?? '-'}`,
                width: { size: 70, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Source',
                width: { size: 15, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Screening',
                width: { size: 15, type: WidthType.PERCENTAGE },
              }),
            ],
          }),
          ...(search.empty === false
            ? search.studies.map((a) => {
                const studyCitation = citations.findByStudyId(a.id)
                return new TableRow({
                  children: [
                    buildTableCell({
                      children: [
                        new TextRun({
                          text: studyCitation.inText,
                          bold: true,
                        }),
                        new TextRun({
                          text: 'Title: ',
                          break: studyCitation.inText ? 1 : 0,
                          bold: true,
                        }),
                        new TextRun({
                          text: a.title,
                        }),
                        new TextRun({
                          text: 'Abstract: ',
                          break: 1,
                          bold: true,
                        }),
                        new TextRun({
                          text: a.abstract,
                        }),
                      ],
                      ...(a.stateText === StudyState.INCLUDED && {
                        shading: {
                          fill: a.fillColor,
                        },
                      }),
                    }),
                    buildTableCell({
                      children: [new TextRun(search.source)],
                      ...(a.stateText === StudyState.INCLUDED && {
                        shading: {
                          fill: a.fillColor,
                        },
                      }),
                    }),

                    buildTableCell({
                      ...(a.stateText === StudyState.INCLUDED && {
                        shading: {
                          fill: a.fillColor,
                        },
                      }),
                      children: [
                        new TextRun({
                          ...(a.stateText === StudyState.INCLUDED && {
                            bold: true,
                          }),
                          text: a.stateText,
                        }),
                      ],
                    }),
                  ],
                })
              })
            : [
                new TableRow({
                  children: [
                    buildTableCell({
                      children: [
                        new TextRun({
                          text: 'The search returned no records',
                          bold: true,
                        }),
                      ],
                    }),
                    buildTableCell({
                      children: [new TextRun(search.source)],
                    }),

                    buildTableCell({
                      children: [new TextRun('-')],
                    }),
                  ],
                }),
              ]),
        ],
      }),
    ]
  }
  const screeningResults = generateReviewScreeningResults(review)
  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: [
      new Paragraph({
        text: 'Screening details',
        heading: HeadingLevel.HEADING_2,
      }),

      ...(screeningResults
        .map((search) => {
          return buildSearchScreeningResultBreakdownSection(search)
        })
        .flat() ?? []),
    ],
  }
}

async function buildReferencesSection(
  review: Review,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
): Promise<ISectionOptions> {
  const studies = review.studies.filter((s) => s.state !== StudyState.DUPLICATE)

  const studiesCitation: { inText: string; fullText: string }[] = studies.map(
    (a) => {
      const studyCitation = citations.findByStudyId(a.id)
      return {
        inText: studyCitation ? studyCitation.inText : '-',
        fullText: studyCitation ? studyCitation.fullText : '-',
      }
    },
  )

  studiesCitation.sort((a, b) => (a.inText || '').localeCompare(b.inText || ''))

  const children = [
    new Paragraph({
      text: 'References',
      heading: HeadingLevel.HEADING_1,
    }),
  ]

  studiesCitation.forEach((s) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.inText ?? '',
            bold: true,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.fullText ?? '',
          }),
        ],
      }),
    )
  })

  return {
    children,
  }
}

function buildTablesReferencesSection(): ISectionOptions {
  const children = [
    new Paragraph({
      text: 'Tables references',
      heading: HeadingLevel.HEADING_1,
    }),
  ]

  tables.forEach((a) => {
    children.push(
      new Paragraph({
        children: [
          new InternalHyperlink({
            children: [
              new TextRun({
                text: a,
              }),
            ],
            anchor: a,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
  })

  return {
    children,
  }
}

function buildFiguresReferencesSection(): ISectionOptions {
  const children = [
    new Paragraph({
      text: 'Figures references',
      heading: HeadingLevel.HEADING_1,
    }),
  ]

  figures.forEach((a) => {
    children.push(
      new Paragraph({
        children: [
          new InternalHyperlink({
            children: [
              new TextRun({
                text: a,
              }),
            ],
            anchor: a,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
  })

  return {
    children,
  }
}

enum HeaderCellFillColor {
  DEFAULT = '#cccccc',
  QUESTION = '#e0eaf3',
  SUB_HEADER = '#FFFFFF',
}

function buildTableHeaderCell({
  text,
  rowSpan = 1,
  columnSpan = 1,
  width,
  fillColor = HeaderCellFillColor.DEFAULT,
}: {
  text: string
  rowSpan?: number
  columnSpan?: number
  width?: ITableWidthProperties
  fillColor?: HeaderCellFillColor
}) {
  return new TableCell({
    columnSpan,
    rowSpan,
    width,
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            text,
            bold: true,
            size: 20,
          }),
        ],
        spacing: { before: 0 },
      }),
    ],
    margins: {
      bottom: 0,
      top: 0,
      left: 28,
      right: 28,
    },
    shading: {
      fill: fillColor,
    },
  })
}

function buildTableCell({
  children,
  rowSpan = 1,
  columnSpan = 1,
  width,
  shading,
}: {
  rowSpan?: number
  columnSpan?: number
  children: ParagraphChild[]
  width?: ITableWidthProperties
  shading?: IShadingAttributesProperties
}) {
  return new TableCell({
    columnSpan,
    rowSpan,
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children,
        style: 'tableCell',
      }),
    ],
    shading,
    margins: {
      bottom: 0,
      top: 0,
      left: 28,
      right: 28,
    },
    width,
  })
}
function buildAttributeExtractionSection(
  review: Review,
  attributesStructure: AttributeStructure[],
  studies: Study[],
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
): ISectionOptions {
  let content: (Paragraph | Table)[] = []

  const cslStyle = review.project?.cslStyle
    ? review.project?.cslStyle
        .charAt(0)
        .toUpperCase()
        .replaceAll('-', ' ')
        .slice(1)
    : '-'
  if (attributesStructure.length > 0) {
    if (studies.length > 0) {
      attributesStructure.forEach((a) => {
        const headerRow = new TableRow({
          tableHeader: true,
          children: [
            buildTableHeaderCell({
              text: `Reference of ${cslStyle ?? '-'}`,
              width: { size: 25, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: a.label,
              width: { size: 75, type: WidthType.PERCENTAGE },
            }),
          ],
        })

        const studyRows = studies.map((s) => {
          const studyReference = citations.findByStudyId(s.id).inText ?? '-'
          const studyAttributeValue =
            s.dataExtraction.attributes.find(
              (ds) => ds.attributeStructureId === a.id,
            )?.value ?? '-'

          return new TableRow({
            children: [
              buildTableCell({
                children: [
                  new TextRun({
                    text: studyReference,
                  }),
                ],
              }),
              buildTableCell({
                children: [
                  new TextRun({
                    text: studyAttributeValue,
                  }),
                ],
              }),
            ],
          })
        })

        const dataExtractionTableIndex = tableIndex()
        tables.push(
          `Table ${dataExtractionTableIndex}: Data extraction for ${a.label}`,
        )

        const tableContent = [
          new Paragraph(
            `Table ${dataExtractionTableIndex} summarizes data extraction for ${a.label}.`,
          ),
          new Paragraph({
            children: [
              new Bookmark({
                id: `Table ${dataExtractionTableIndex}: Data extraction for ${a.label}`,
                children: [
                  new TextRun(
                    `Table ${dataExtractionTableIndex}: Data extraction for ${a.label}`,
                  ),
                ],
              }),
            ],
            style: 'Caption',
          }),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [headerRow, ...studyRows],
          }),
        ]

        content = content.concat(tableContent)
      })
    } else {
      content.push(new Paragraph({ text: 'No studies included.' }))
    }
  } else {
    content.push(new Paragraph({ text: 'No attributes found.' }))
  }

  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.PORTRAIT,
        },
      },
    },
    children: [
      new Paragraph({
        text: 'Data extraction',
        heading: HeadingLevel.HEADING_1,
      }),
      ...content,
    ],
  }
}
