<template>
  <FloatingMenu v-tooltip="study.fullTextScreening">
    <template #activator="{ show }">
      <StudyActionButton
        :is-loading="isLoading"
        :state="
          study.fullTextScreening ===
          FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE
            ? ButtonState.blue
            : (study.titleAndAbstractScreening !== 'Included' &&
                  (review.entity.value.plan?.screeningPlan
                    .titleAndAbstractCriteria.length ?? 0) > 0) ||
                study.isDuplicate
              ? ButtonState.disabled
              : study.fullTextScreening === '' && !study.isDuplicate
                ? ButtonState.inactive
                : study.fullTextScreening === 'Included'
                  ? ButtonState.green
                  : ButtonState.red
        "
        :is-review-read-only="review.isReviewReadonly.value"
        @click.stop="show"
      >
        <span>{{
          review.entity.value.plan?.screeningPlan.titleAndAbstractCriteria
            .length === 0
            ? 'Screening'
            : 'Full text'
        }}</span>
      </StudyActionButton>
    </template>
    <template #content>
      <div
        class="flex flex-col items-stretch divide-y divide-gray-200 w-full"
        @click.stop.prevent
      >
        <button
          v-tooltip.right.redBackground="
            !study.pdfFile
              ? 'You must upload the full-text PDF to be able to screen on full text. <br> Drag/drop the PDF on the record card to upload it.'
              : ''
          "
          class="px-6 py-3 text-left"
          :class="[
            {
              'bg-green-50 text-green-700 hover:brightness-95': study.pdfFile,
              'bg-gray-200 text-gray-500 cursor-auto': !study.pdfFile,
              'brightness-95': study.fullTextScreening === INCLUDED,
            },
          ]"
          @click.stop="toggleIncluded"
        >
          Included
        </button>

        <button
          class="px-6 py-3 text-left"
          :class="{
            'bg-gray-200 text-gray-500 cursor-auto': study.pdfFile,
            'bg-blue-50 text-blue-700 hover:brightness-95': !study.pdfFile,
            'brightness-95':
              study.fullTextScreening ===
              FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE,
          }"
          @click.stop="toggleFullTextCannotBeRetrieved"
        >
          Full text cannot be retrieved
        </button>
        <template
          v-for="criterion in review.entity.value?.plan?.screeningPlan?.fullTextCriteria?.filter(
            (c) => c !== FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE,
          )"
          :key="criterion"
        >
          <button
            v-tooltip.right="
              !study.pdfFile &&
              (review.entity.value.plan?.screeningPlan?.titleAndAbstractCriteria
                .length ?? 0) > 0
                ? 'You must upload the full-text PDF to be able to screen on full text. <br> Drag/drop the PDF on the record card to upload it.'
                : ''
            "
            class="px-6 py-3 text-left"
            :class="{
              'bg-red-50 text-red-700 hover:brightness-95': study.pdfFile,
              'bg-gray-200 text-gray-500 cursor-auto': !study.pdfFile,
              'brightness-95': study.fullTextScreening === criterion,
            }"
            @click.stop="toggleExcluded(criterion)"
          >
            {{ criterion }}
          </button>
        </template>
      </div>
    </template>
  </FloatingMenu>
</template>
<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ButtonState } from '@app/types'
import { ReviewKey } from '@app/views/Review/use-review'
import { Study } from '@core/domain/models/study.model'
import { FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE } from '@core/domain/types/full-text-cannot-be-retrieved-screening-criterion.type'
import { INCLUDED } from '@core/domain/types/included-screening-criterion.type'
import StudyActionButton from '../StudyActionButton.vue'
import { injectStrict } from '@app/utils/injectStrict'

const review = injectStrict(ReviewKey)
const props = defineProps<{
  study: Study
  isLoading?: boolean
  isAbstractValid?: boolean
}>()

const emit = defineEmits<{
  (e: 'clearFullTextScreening'): void
  (e: 'setFullTextScreening', value: string): void
}>()

function toggleIncluded() {
  if (props.study.fullTextScreening === INCLUDED) emit('clearFullTextScreening')
  else if (props.study.pdfFile) emit('setFullTextScreening', INCLUDED)
}

function toggleFullTextCannotBeRetrieved() {
  if (
    props.study.fullTextScreening ===
    FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE
  )
    emit('clearFullTextScreening')
  else if (!props.study.pdfFile)
    emit('setFullTextScreening', FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE)
}

function toggleExcluded(criterion: string) {
  if (props.study.fullTextScreening === criterion)
    emit('clearFullTextScreening')
  else if (
    !!props.study.pdfFile ||
    review.entity.value.plan?.screeningPlan.titleAndAbstractCriteria.length ===
      0
  )
    emit('setFullTextScreening', criterion)
}
</script>
