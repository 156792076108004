import { Api } from './review-manager-api'
import * as Sentry from '@sentry/vue'

export class ApiInterceptors {
  constructor(private _reviewManagerService: Api<unknown>) {}

  registerInterceptors() {
    this._reviewManagerService.instance.interceptors.response.use(
      (response) => response,
      (e) => {
        if (e.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          Sentry.captureException(e.response.data)
        } else if (e.request) {
          // The request was made but no response was received
          // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          Sentry.captureException(e.request)
        } else {
          // Something happened in setting up the request that triggered an error
          Sentry.captureException(e.message)
        }
        Sentry.captureException(e)
        return Promise.reject(e)
      },
    )
  }
}
