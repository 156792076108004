<template>
  <ImportSourceCard
    :import-source="cochrane"
    :tooltip="{
      text: 'Use the Evidence browser extension to import studies from Cochrane.',
      url: '/docs/reviews.html#cochrane',
    }"
  >
    <template #bottom>
      <a
        v-if="isExtensionInstalled"
        :href="cochrane.url"
        rel="noopener"
        target="_blank"
        class="w-full bg-primary text-white rounded-lg px-4 py-2 font-medium block text-center"
      >
        Go to Cochrane Library
      </a>
      <div
        v-else
        class="rounded-md border-primary/40 text-black/80 text-center border px-2 py-2 text-sm"
      >
        Install the
        <a
          href="https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi"
          target="_blank"
          rel="noopener"
          class="text-primary underline"
        >
          chrome extension
        </a>
        to import from {{ cochrane.name }}
      </div>
    </template>
  </ImportSourceCard>
</template>
<script setup lang="ts">
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import useWebExtension from '@app/composables/useWebExtension'
const { isExtensionInstalled } = useWebExtension()

const builtInImportSources = useBuiltInImportSources()

const cochrane = builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!
</script>
