<template>
  <div class="flex flex-col overflow-auto" data-navigation="Scope">
    <h3
      class="text-2xl text-primary font-medium flex items-center gap-2 px-4 pt-4"
    >
      Scope
    </h3>
    <div v-if="shouldShowPresetSelection" class="p-4 flex-shrink-0 container">
      <GenericAutoComplete
        :readonly="review.isPlanReadonly.value"
        placeholder="select a preset"
        :items="reviewPresets"
        :model-value="preset"
        :item-options="{
          displayProperty: 'text',
          filterProperties: ['text', 'value'],
          valueProperty: 'value',
        }"
        @update:model-value="setReviewPreset"
      >
        <template #label="{ id }">
          <div class="flex gap-1 items-center mb-1">
            <label
              :for="id"
              class="text-sm text-primary font-medium flex items-center gap-2"
            >
              Preset
            </label>
            <InfoTooltip
              text="Each preset automatically generates a specific set of questions tailored for different types of reviews, simplifying the data extraction process."
              url="/docs/reviews.html#configuring-presets"
            />
          </div>
        </template>
      </GenericAutoComplete>
    </div>

    <keep-alive>
      <component :is="currentComponent"></component>
    </keep-alive>
  </div>
</template>

<script setup lang="ts">
import DeviceSpecificSearch from './Presets/DeviceSpecificSearchPreset.vue'
import { computed } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { HttpException } from '@core/exceptions/http.exception'
import MedicalBackgroundPreset from './Presets/MedicalBackgroundPreset.vue'
import EffectivenessAndSafetyPreset from './Presets/EffectivenessAndSafetyPreset.vue'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import CustomPreset from './Presets/CustomPreset.vue'
import InfoTooltip from '@app/components/Global/InfoTooltip.vue'

const review = injectStrict(ReviewKey)

const components = {
  [ReviewPreset.MEDICAL_BACKGROUND]: MedicalBackgroundPreset,
  [ReviewPreset.EFFECTIVENESS_AND_SAFETY]: EffectivenessAndSafetyPreset,
  [ReviewPreset.DEVICE_SPECIFIC_SEARCH]: DeviceSpecificSearch,
  [ReviewPreset.CUSTOM]: CustomPreset,
}

const preset = computed(() => {
  return review.entity.value.plan?.preset
})

const shouldShowPresetSelection = computed(() => {
  let shouldShow = true
  const plan = review.entity.value.plan
  if (
    (plan?.customPreset?.attributesStructure?.length ?? 0) > 0 ||
    (plan?.effectivenessAndSafetyPreset?.attributesStructure?.length ?? 0) >
      0 ||
    (plan?.medicalBackgroundPreset?.attributesStructure?.length ?? 0) > 0 ||
    (plan?.pmcfPreset?.attributesStructure?.length ?? 0) > 0
  ) {
    shouldShow = false
  }
  return shouldShow
})

const currentComponent = computed(() => {
  return preset.value ? components[preset.value!] : 'div'
})

const reviewPresets = [
  {
    text: 'Medical background',
    value: ReviewPreset.MEDICAL_BACKGROUND,
  },
  {
    text: 'Effectiveness and safety of a medical procedure',
    value: ReviewPreset.EFFECTIVENESS_AND_SAFETY,
  },
  {
    text: 'Device-specific search',
    value: ReviewPreset.DEVICE_SPECIFIC_SEARCH,
  },

  {
    text: 'Custom preset',
    value: ReviewPreset.CUSTOM,
  },
]

async function setReviewPreset(newPreset?: unknown) {
  if (!newPreset || typeof newPreset !== 'string') return
  console.log(newPreset)
  try {
    await review.setReviewPreset(
      review.entity.value.id,
      newPreset as ReviewPreset,
    )
  } catch (e) {
    const error = e as HttpException
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  }
}
</script>
