import { Study } from '@core/domain/models/study.model'

export function generateRisContent(studies: Study[]): string {
  return studies
    .map((study) => {
      let risContent = `TY  - JOUR\n`

      if (study.title) {
        risContent += `TI  - ${study.title}\n`
      }
      if (study.abstract) {
        risContent += `AB  - ${study.abstract}\n`
      }
      study.authors.forEach((author) => {
        risContent += `AU  - ${author}\n`
      })
      if (study.doi) {
        risContent += `DO  - ${study.doi}\n`
      }
      if (study.url) {
        risContent += `UR  - ${study.url}\n`
      }
      if (study.publishYear) {
        risContent += `PY  - ${study.publishYear}\n`
      }
      if (study.journal) {
        risContent += `JO  - ${study.journal}\n`
      }
      if (study.volumeNumber) {
        risContent += `VL  - ${study.volumeNumber}\n`
      }
      if (study.issueNumber) {
        risContent += `IS  - ${study.issueNumber}\n`
      }
      if (study.pagesNumber) {
        risContent += `SP  - ${study.pagesNumber}\n`
      }

      risContent += `ER  -\n\n` // Add a line break after each record for separation
      return risContent
    })
    .join('')
}
