import { useStorage } from '@vueuse/core'
import { v4 as uuid } from 'uuid'
import { computed } from 'vue'

export type AttributeSchema = {
  id: string
  label: string
  description?: string
  dataSchema: DataSchema
  children: AttributeSchema[]
}

export type DataSchema = {
  id: string
  canAddEntries?: boolean
  dataEntryPropertiesSchema?: DataEntryPropertySchema[]
}

export type DataEntryPropertySchema = {
  id: string
  label: string
  description?: string
}

export default (reviewId: number) => {
  const medicalBackgroundAttributeStructure = useStorage<AttributeSchema[]>(
    `attributeStructure-medical-background-${reviewId}`,
    [],
  )

  const effectivenessAndSafetyOfMedicalProcedure = useStorage<
    AttributeSchema[]
  >(`attributeStructure-effectiveness-${reviewId}`, [])

  function createAttributeStructure(
    data?: Partial<AttributeSchema>,
  ): AttributeSchema {
    return {
      id: uuid(),
      label: data?.label ?? '',
      description: data?.description ?? '',
      children: data?.children ?? [],
      dataSchema: data?.dataSchema ?? {
        id: uuid(),
        canAddEntries: false,
        dataEntryPropertiesSchema: [],
      },
    }
  }

  const structure = computed<AttributeSchema[]>(() => {
    return medicalBackgroundAttributeStructure.value
  })

  return {
    medicalBackgroundAttributeStructure,
    effectivenessAndSafetyOfMedicalProcedure,
    structure,
    createAttributeStructure,
  }
}
