<template>
  <template v-if="review.displayOptions.isAppraisalStageActive.value">
    <div class="border border-[#D9E4EA] items-center py-3">
      <div class="w-full justify-evenly gap-2 flex px-4">
        <template
          v-if="
            review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable
          "
        >
          <FloatingMenu
            v-for="(criterion, appraisalCriterionIndex) in review.entity.value
              ?.plan?.appraisalCriteria"
            :key="criterion.id + appraisalCriterionIndex"
            placement="bottom"
          >
            <template #activator="{ show }">
              <div
                v-tooltip="
                  criterion.answers?.find(
                    (a) => a.id === study.appraisal?.[criterion.id],
                  )?.title
                "
              >
                <StudyActionButton
                  class="w-[65px]"
                  :state="
                    study.state === StudyState.INCLUDED &&
                    study.appraisal?.[criterion.id]
                      ? ButtonState.appraised
                      : study.state !== StudyState.INCLUDED
                        ? ButtonState.disabled
                        : ButtonState.inactive
                  "
                  :is-review-read-only="review.isLocked.value"
                  @click="show()"
                >
                  <p v-if="!study.appraisal?.[criterion.id]">
                    {{ criterion.id }}
                  </p>
                  <p v-else>
                    {{
                      criterion.id +
                      (criterion.answers?.findIndex(
                        (a) => a.id === study.appraisal?.[criterion.id],
                      ) +
                        1)
                    }}
                  </p>
                </StudyActionButton>
              </div>
            </template>
            <template #content="{ hide }">
              <div class="flex flex-col w-[350px] divide-y divide-gray-200">
                <p class="px-4 py-2 text-left font-bold">
                  {{ criterion.question }}
                </p>
                <button
                  v-for="(answer, answerIndex) in criterion.answers"
                  :key="answer.id"
                  class="flex flex-row px-4 py-2 text-left w-full gap-4"
                  :class="
                    study.appraisal?.[criterion.id] === answer.id
                      ? 'bg-slate-500 text-white '
                      : 'hover:bg-gray-200'
                  "
                  @click="
                    study.appraisal?.[criterion.id] === answer.id
                      ? clearStudyAppraisal(study.id, criterion.id, hide)
                      : appraiseStudy(study.id, criterion.id, answer.id, hide)
                  "
                >
                  <p>{{ criterion.id + (answerIndex + 1) }}</p>
                  <p>{{ answer.title }}</p>
                </button>
              </div>
            </template>
          </FloatingMenu>
        </template>
      </div>
    </div>
    <div class="border border-[#D9E4EA] items-center py-3">
      <div class="w-full justify-evenly gap-2 flex px-4">
        <OxfordLevelOfEvidence
          v-if="
            review.entity.value?.plan?.appraisalPlan
              .isOxfordLevelOfEvidenceApplicable
          "
          :study="study"
        />
      </div>
    </div>
    <div class="border border-[#D9E4EA] items-center py-3">
      <div class="w-full justify-evenly gap-2 flex px-4">
        <PeerReviewStatus
          v-if="
            review.entity.value?.plan?.appraisalPlan
              .isPeerReviewStatusApplicable
          "
          :study="study"
        />
      </div>
    </div>
  </template>
</template>
<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ButtonState, SnackbarState, StudyState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { ReviewKey } from '@app/views/Review/use-review'
import { Study } from '@core/domain/models/study.model'
import { Id } from '@core/domain/types/id.type'
import { HttpException } from '@core/exceptions/http.exception'

import StudyActionButton from '../../StudyActionButton.vue'
import { injectStrict } from '@app/utils/injectStrict'
import OxfordLevelOfEvidence from './OxfordLevelOfEvidence.vue'
import PeerReviewStatus from './PeerReviewStatus.vue'

const review = injectStrict(ReviewKey)

const snackbar = useSnackbar()
const loading = useLoading()

defineProps<{
  study: Study
}>()

async function appraiseStudy(
  studyId: Id,
  criterionId: string,
  gradeId: string,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.appraiseStudy(studyId, { criterionId, gradeId })
    snackbar.show(SnackbarState.SUCCESS, 'study appraised')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function clearStudyAppraisal(
  studyId: Id,
  criterionId: string,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.clearStudyAppraisal(studyId, criterionId)
    snackbar.show(SnackbarState.SUCCESS, 'study appraisal cleared')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
