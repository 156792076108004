import { ImportSource } from '@core/domain/models/import-source.model'
import { Api } from './review-manager-api'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'

export class BuiltInImportSourcesRepository {
  constructor(private _reviewManagerService: Api<unknown>) {}

  async find(): Promise<ImportSource[]> {
    const { data } =
      await this._reviewManagerService.api.builtInImportSourcesControllerFindBuiltInImportSources()
    return data.map((d) => {
      const { type, ...source } = d
      return new ImportSource({
        ...source,
        type: type as ImportSourceType,
      })
    })
  }
}
