<template>
  <FloatingMenu v-tooltip="study.titleAndAbstractScreening">
    <template #activator="{ show }">
      <StudyActionButton
        :class="
          study.titleAndAbstractScreening ===
          FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE
            ? 'bg-screening-blue '
            : ''
        "
        :is-loading="isLoading"
        :state="
          study.isDuplicate
            ? ButtonState.disabled
            : study.titleAndAbstractScreening === ''
              ? ButtonState.inactive
              : study.titleAndAbstractScreening === 'Included'
                ? ButtonState.green
                : ButtonState.red
        "
        :is-review-read-only="review.isReviewReadonly.value"
        @click.stop="show"
      >
        Abstract
      </StudyActionButton>
    </template>
    <template #content>
      <div
        class="flex flex-col items-stretch w-full overflow-y-auto"
        @click.stop.prevent
      >
        <button
          class="px-6 py-3 text-left bg-green-50 text-green-500 hover:brightness-95"
          :class="{
            'brightness-95': study.titleAndAbstractScreening === INCLUDED,
          }"
          @click.stop="toggleIncluded"
        >
          Included
        </button>
        <template
          v-for="criterion in review.entity.value?.plan?.screeningPlan
            ?.titleAndAbstractCriteria"
          :key="criterion"
        >
          <button
            class="px-6 py-3 text-left bg-red-50 text-red-500 hover:brightness-95"
            :class="{
              'brightness-95': study.titleAndAbstractScreening === criterion,
            }"
            @click.stop="toggleExcluded(criterion)"
          >
            {{ criterion }}
          </button>
        </template>
      </div>
    </template>
  </FloatingMenu>
</template>
<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ButtonState } from '@app/types'
import { ReviewKey } from '@app/views/Review/use-review'
import { Study } from '@core/domain/models/study.model'
import { FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE } from '@core/domain/types/full-text-cannot-be-retrieved-screening-criterion.type'
import { INCLUDED } from '@core/domain/types/included-screening-criterion.type'
import StudyActionButton from '../StudyActionButton.vue'
import { injectStrict } from '@app/utils/injectStrict'

const review = injectStrict(ReviewKey)
const props = defineProps<{
  study: Study
  isLoading?: boolean
  isAbstractValid?: boolean
}>()

const emit = defineEmits<{
  (e: 'clearTitleAndAbstractScreening'): void
  (e: 'setTitleAndAbstractScreening', value: string): void
}>()

function toggleIncluded() {
  if (props.study.titleAndAbstractScreening === INCLUDED)
    emit('clearTitleAndAbstractScreening')
  else if (
    !!props.study.pdfFile ||
    (review.entity.value?.plan?.screeningPlan.titleAndAbstractCriteria
      ?.length ?? 0) > 0
  )
    emit('setTitleAndAbstractScreening', INCLUDED)
}

function toggleExcluded(criterion: string) {
  if (props.study.titleAndAbstractScreening === criterion)
    emit('clearTitleAndAbstractScreening')
  else emit('setTitleAndAbstractScreening', criterion)
}
</script>
